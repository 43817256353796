<template>
  <div class="NovaCotacao">
    <topoInterno
      titulo="Cotação Online"
      @OpenMenuLateral="menuOpen = true"
    ></topoInterno>
    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div
      class="topoForm"
      :style="`color:${temaCor.tituloPrimario}; border-left-color:${temaCor.tituloPrimario}`"
    >
      {{ verificaPasso() }}
    </div>
    <div
      class="conteudoInterno text-center p-3 text-secondary"
      :class="`${passo == 5 ? '' : 'mt-5'}`"
    >
      <b-form
        @submit.stop.prevent="onSubmit"
        @reset="onReset"
        class="text-left"
      >
        <div v-if="passo == 0 && permiteAlterarRegional == true">
          <b-form-group
            id="Regional"
            label="Selecionar Cooperativa"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.regional"
              :options="regionais"
              name="Cooperativa"
              id="Cooperativa"
              value-field="id"
              text-field="label"
              v-validate="{ required: false }"
              :state="validateState('Cooperativa')"
              :class="validateClass('Cooperativa')"
              @input="buscaDivisoes()"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="Regional"
            label="Selecionar Regional"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.idDivisao"
              :options="divisoes"
              name="Regional"
              id="Regional"
              value-field="id"
              text-field="label"
              v-validate="{ required: false }"
              :state="validateState('Regional')"
              :class="validateClass('Regional')"
            ></b-form-select>
          </b-form-group>
        </div>

        <div v-if="passo == 1">
          <b-form-group
            id="input-group-2"
            label="Tipo de Proposta"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.tipoProposta"
              :options="tipoPropostaSelector"
              name="Tipo de Proposta"
              v-validate="{ required: true }"
              :state="validateState('Tipo de Proposta')"
              :class="validateClass('Tipo de Proposta')"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            v-if="form.isZero == false"
            id="input-group-2"
            label
            label-for="input-2"
          >
            <b-row>
              <b-col class="text-center mb-4">
                Informe a
                <b>PLACA</b> do veículo
              </b-col>
            </b-row>
            <the-mask
              mask="AAA-#XX#"
              style="text-transform: uppercase"
              id="input-2"
              class="form-control"
              v-model="form.placa"
              :placeholder="
                form.tipoProposta == 'Substituição'
                  ? 'Placa que vai entrar'
                  : 'Placa'
              "
              name="Placa"
              :masked="true"
              @keyup.native="keymonitorPlaca"
              v-validate="{ required: true, length: 8 }"
              :state="validateState('Placa')"
              :disabled="propostaParaSomenteAgregado"
            />
          </b-form-group>
          <b-form-group
            v-else-if="verificaEmpresa()"
            label="Informe o chassi do veículo"
            id="input-group-2"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              style="text-transform: uppercase; margin-bottom: 10px"
              maxlength="17"
              v-validate="{ required: true, length: 17 }"
              v-model="form.chassi"
              name="Chassi"
              placeholder="Chassi"
              :state="validateState('Chassi')"
              v-if="verificaEmpresa()"
              @keyup.native="validaChassiBaseBP"
            ></b-form-input>

            <b-form-group
              id="input-group-2"
              label-for="input-2"
              style="margin-top: 15px"
              label="Data de saída do veículo na NF"
              class="mb-4 mt-04"
              v-if="verificaEmpresa()"
            >
              <the-mask
                class="form-control"
                id="input-2"
                :masked="true"
                v-model="form.dataNf"
                name="Data da Nota Fiscal"
                v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                mask="##/##/####"
                placeholder="Data de saída do veículo na NF"
                :state="validateState('Data da Nota Fiscal')"
                :danger="validateClass('Data da Nota Fiscal')"
              ></the-mask>
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="isCarroZero"
              switch
              :disabled="btnVeiculoZeroHabilitado"
              >Veículo Zero</b-form-checkbox
            >
          </b-form-group>

          <b-form-group v-if="verificaEmpresa()">
            <b-form-checkbox
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="propostaParaSomenteAgregado"
              switch
              @input="ajustarPropostaParaSomenteAgregado"
              >Proposta para somente agregado</b-form-checkbox
            >
          </b-form-group>

          <b-form-group id="input-group-5">
            <b-form-checkbox
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="form.permiteInvoiceSelfService"
              switch
              >Opcionais disponíveis para edição do associado.</b-form-checkbox
            >
          </b-form-group>
          <div
            v-if="
              form.isZero == true &&
              !utils.verificaEmpresaBemProtege(decoded.id_empresa)
            "
          >
            <b-form-group id="input-group-2" label label-for="input-2">
              <b-form-input
                id="input-2"
                style="text-transform: uppercase"
                maxlength="17"
                v-validate="{ required: true, length: 17 }"
                v-model="form.chassi"
                name="Chassi"
                placeholder="Chassi"
                :state="validateState('Chassi')"
                @keyup.native="validaChassiBaseBP"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="form.isZero == true"
              id="input-group-2"
              label="Data da NF"
              label-for="input-2"
            >
              <the-mask
                class="form-control"
                id="input-2"
                :masked="true"
                v-model="form.dataNf"
                name="Data da Nota Fiscal"
                v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                mask="##/##/####"
                placeholder="Data da Nota fiscal"
                :state="validateState('Data da Nota Fiscal')"
                :danger="validateClass('Data da Nota Fiscal')"
              ></the-mask>
            </b-form-group>

            <b-form-group
              v-if="form.isZero == true"
              id="input-group-2"
              label="Número da NF"
              label-for="input-2"
            >
              <b-form-input
                type="number"
                id="input-2"
                v-model="form.nf"
                name="Nota Fiscal"
                placeholder="Número da Nota fiscal"
                v-validate="{ required: true }"
                :state="validateState('Nota Fiscal')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="form.isZero == true"
              id="input-group-2"
              label="Valor da NF"
              label-for="input-2"
            >
              <money
                class="form-control"
                name="Valor da Nota Fiscal"
                id="Valor da Nota Fiscal"
                placeholder="Valor da Nota"
                v-model="form.valorNf"
                v-validate="{ required: true, min_value: 0.01 }"
                :state="validateState('Valor da Nota Fiscal')"
                :danger="validateClass('Valor da Nota Fiscal')"
                v-bind="money"
              ></money>
            </b-form-group>
          </div>
        </div>
        <div v-if="passo == 2">
          <b-form-group id="input-group-2" label label-for="input-2">
            <b-form-input
              id="Email"
              name="Email"
              placeholder="Email"
              v-model="form.email"
              v-validate="{ email: true }"
              :state="validateState('Email')"
            ></b-form-input>
          </b-form-group>
          <div
            v-if="form.preenchidoAutomatico == true"
            class="alert alert-warning"
          >
            Ao Avançar, os dados do Associado serão atualizados
          </div>
          <b-form-group
            id="input-group-2"
            label="Tipo de Pessoa"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.tipoPessoa"
              :options="tiposPessoas"
              name="Tipo de Pessoa"
              v-validate="{ required: true }"
              :state="validateState('Tipo de Pessoa')"
              :class="validateClass('Tipo de Pessoa')"
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-group-2" label label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.nome"
              placeholder="Nome Completo"
              name="Nome Completo"
              v-validate="{ required: true }"
              :state="validateState('Nome Completo')"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label label-for="input-2">
            <the-mask
              id="input-2"
              class="form-control"
              v-model="form.telefone"
              name="Telefone"
              placeholder="Telefone"
              :masked="true"
              mask="(##) ####-####"
              :state="validateState('Telefone')"
              :class="validateClass('Telefone')"
            ></the-mask>
          </b-form-group>

          <b-form-group id="input-group-2" label label-for="input-2">
            <the-mask
              id="input-2"
              class="form-control"
              v-model="form.celular"
              name="Celular"
              placeholder="Celular"
              :masked="true"
              mask="(##) #####-####"
              v-validate="{ required: true }"
              :state="validateState('Celular')"
              :class="validateClass('Celular')"
            ></the-mask>
          </b-form-group>

          <b-form-group>
            <the-mask
              type="text"
              class="form-control"
              v-model="cep"
              v-validate="{ length: 10 }"
              placeholder="CEP"
              mask="##.###-###"
              id="Cep"
              name="Cep"
              :masked="true"
              @keyup.native="keymonitor"
              :state="validateState('Cep')"
              :class="validateClass('Cep')"
            ></the-mask>
          </b-form-group>
          <div v-if="encontrouCep == false">
            <b-form-group id="input-group-2" label="Estado" label-for="input-2">
              <b-select
                :options="estados"
                name="Estado"
                id="Estado"
                v-model="form.estado"
                @change="buscaDadosSelectsCidades"
                v-validate="{ required: true }"
                :state="validateState('Estado')"
                :class="validateClass('Estado')"
              ></b-select>
            </b-form-group>
            <b-form-group id="input-group-2" label="Cidade" label-for="input-2">
              <b-select
                :options="cidades"
                name="Cidade"
                id="Cidade"
                v-model="form.cidade"
                v-validate="{ required: true }"
                :state="validateState('Cidade')"
                :class="validateClass('Cidade')"
              ></b-select>
            </b-form-group>
          </div>
          <div v-if="encontrouCep == true">
            <b-form-group
              v-if="exibeCidade == true"
              id="input-group-2"
              label
              label-for="input-2"
            >
              <b-form-input
                id="Cidade"
                readonly
                name="Cidade"
                placeholder="Cidade"
                v-model="form.cidade"
                v-validate="{ required: true }"
                :state="validateState('Cidade')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="exibeCidade == true"
              id="input-group-2"
              label
              label-for="input-2"
            >
              <b-form-input
                id="Estado"
                readonly
                name="Estado"
                placeholder="Estado"
                v-model="form.estado"
                v-validate="{ required: true }"
                :state="validateState('Estado')"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div v-if="passo == 3" class="restricoes">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="form.restricoesSelecionadas"
            name="flavour-2"
          >
            <b-form-checkbox
              v-for="item in restricoes"
              :key="item.idRestricao"
              class="w-100 mb-3"
              :value="item.idRestricao"
              :disabled="item.obrigatorio"
              size="lg"
            >
              <b class="mr-2">{{ item.descricao }}</b>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <div v-if="passo == 4">
          <div v-if="placaEncontrada == true">
            <b-form-group
              class="mt-3"
              id="input-group-2"
              label="Tipo de Veículo"
              label-for="input-2"
            >
              <b-form-select
                v-model="form.idTipoFipe"
                :options="tiposFipe"
                name="Tipo de Veículo"
                v-validate="{ required: true }"
                :state="validateState('Tipo de Veículo')"
                @change="selecionaTipoFipe(), (mostrarModalGrupos = true)"
                :disabled="habilitaAlterarGrupo"
              ></b-form-select>
            </b-form-group>

            <!-- Input a substituir o select Grupo de Veículo -->
            <b-form-group
              id="input-group-2"
              label="Grupo do Veículo"
              label-for="input-2"
            >
              <b-row>
                <b-col :cols="!habilitaAlterarGrupo ? 9 : 12">
                  <b-form-input
                    id="Grupo do Veículo"
                    name="Grupo do Veículo"
                    v-model="form.idGrupo.valor"
                    v-validate="{ required: true }"
                    :state="validateState('Grupo do Veículo')"
                    :class="validateState('Grupo do Veículo')"
                    :disabled="true"
                  />
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex justify-content-end"
                  v-if="!habilitaAlterarGrupo && placaEncontrada"
                >
                  <b-button
                    @click="mostrarModalGrupos = true"
                    pill
                    variant="success"
                    >{{ acaoBotao }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form-group>
            <!-- Input a substituir o select Grupo de Veículo -->

            <b-card
              class="my-3"
              v-if="permiteImplemento == true || permiteAgregado == true"
            >
              <b-form-group id="input-group-4">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.caminhao"
                  switch
                  :disabled="!form.somenteAgregado"
                  @input="desmarcasomenteAgregado()"
                  >Caminhão (Cavalo / Trator)</b-form-checkbox
                >
              </b-form-group>
              <b-form-group id="input-group-4" v-if="permiteImplemento">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.implemento"
                  switch
                  @input="desmarcasomenteAgregado()"
                  >Implemento</b-form-checkbox
                >
              </b-form-group>
              <b-form-group id="input-group-4" v-if="permiteAgregado">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.agregado"
                  switch
                  @input="desmarcasomenteAgregado()"
                  >Agregado</b-form-checkbox
                >
              </b-form-group>
              <!-- <b-form-group id="input-group-4" v-if="permiteImplemento">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.somenteImplemento"
                  switch
                  @input="desmarcaRestante()"
                  >Somente Implemento</b-form-checkbox
                >
              </b-form-group> -->
              <b-button
                v-if="form.somenteAgregado || form.implemento"
                pill
                ref="btnAvancar"
                size="lg"
                variant="info"
                class="btn btn-block mb-2"
                :style="`background-color:${temaCor.botaoFormularios};`"
                @click="
                  openModalComplemento(),
                    (tipoConfiguracaoCaminhao = 'implemento')
                "
                >Adicionar Implemento</b-button
              >
              <b-button
                v-if="form.agregado"
                pill
                ref="btnAvancar"
                size="lg"
                variant="info"
                class="btn btn-block mb-2"
                :style="`background-color:${temaCor.botaoFormularios};`"
                @click="
                  openModalComplemento(),
                    (tipoConfiguracaoCaminhao = 'agregado')
                "
                >Adicionar Agregado</b-button
              >
              <b-card
                title="Implementos"
                class="my-3"
                v-if="form.implementos.length > 0"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in form.implementos"
                    :key="item.id"
                  >
                    {{ item.descricao }} - {{ item.marca }} - {{ item.modelo }}
                    <b-badge variant="success" class="mr-3">{{
                      item.valorCobertura | currency
                    }}</b-badge>

                    <div class="mt-2 text-right">
                      <button
                        class="btn btn-dark mr-3"
                        type="button"
                        id="button-addon2"
                        @click="atualizarComplemento(item, index)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        id="button-addon2"
                        @click="deleteComplemento(item, index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </b-list-group-item> </b-list-group
              ></b-card>

              <b-card
                title="Agregados"
                class="my-3"
                v-if="form.agregados.length > 0"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in form.agregados"
                    :key="item.id"
                  >
                    {{ item.descricao }} - {{ item.marca }} - {{ item.modelo }}
                    <b-badge variant="success" class="mr-3">{{
                      item.valorCobertura | currency
                    }}</b-badge>

                    <div class="mt-2 text-right">
                      <button
                        class="btn btn-dark mr-3"
                        type="button"
                        id="button-addon2"
                        @click="atualizarComplemento(item, index)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        id="button-addon2"
                        @click="deleteComplemento(item, index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </b-list-group-item> </b-list-group
              ></b-card>
            </b-card>

            <!-- Placa exibida quando a placa é invalida -->
            <b-form-group
              v-if="!placaValida"
              id="Placa"
              :label="`Placa`"
              label-for="Placa"
            >
              <the-mask
                mask="AAA-#XX#"
                style="text-transform: uppercase"
                id="input-2"
                class="form-control"
                v-model="form.placa"
                :placeholder="
                  form.tipoProposta == 'Substituição'
                    ? 'Placa que vai entrar'
                    : 'Placa'
                "
                name="Placa"
                :masked="true"
                @keyup.native="keymonitorPlaca"
                v-validate="{ required: true, length: 8 }"
                :state="validateState('Placa')"
                :disabled="propostaParaSomenteAgregado"
              />
            </b-form-group>

            <b-card
              :title="`${dadosPlaca.marcaModelo} - ${dadosPlaca.ano}/${dadosPlaca.anoModelo}`"
            >
              <div class="bg-secondary text-light p-3 mb-3">
                <b>Situação</b> {{ dadosPlaca.situacao }}
              </div>
              <div class="p-3 mb-3">
                Encontramos {{ modelosFipeEncontrados.length }} modelos que se
                assemelham ao seu veículo, selecione o Modelo correto.
              </div>
              <b-list-group>
                <b-list-group-item
                  v-for="item in modelosFipeEncontrados"
                  :key="item.codigo_fipe"
                  :variant="`${item.selecionado == true ? 'success' : ''}`"
                  @input="selecionaModelo(item)"
                >
                  <b-row>
                    <b-col>
                      <b-form-checkbox v-model="item.selecionado"
                        >{{ item.texto_modelo }}

                        <b-badge variant="primary">{{
                          item.codigo_fipe
                        }}</b-badge
                        ><b-badge variant="warning">{{ item.score }}</b-badge>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
              <b-row class="mt-3">
                <b-col>
                  <b-button
                    @click="resetBuscaPlaca()"
                    size="lg"
                    variant="danger"
                    class="btn btn-block"
                    >Nenhum Desses</b-button
                  ></b-col
                >
                <!--b-col>
                  <b-button
                    @click="confirmaModeloFipe()"
                    size="lg"
                    variant="success"
                    class="btn btn-block"
                    :disabled="modeloFipeSelecionado ? false : true"
                    >Confirmar</b-button
                  >
                </b-col-->
              </b-row>

              <b-form-group v-if="exibeCilindrada == true" class="mt-3">
                <label>Cilindradas</label>
                <b-form-input
                  type="number"
                  v-model="form.veiculo.cilindradas"
                  v-validate="{ required: true }"
                  placeholder="Cilindradas"
                  id="Cilindradas"
                  name="Cilindradas"
                  :state="validateState('Cilindradas')"
                ></b-form-input>
              </b-form-group>
            </b-card>
          </div>

          <div v-else>
            <b-row>
              <b-col class="text-center">PLACA: {{ form.placa }}</b-col>
            </b-row>
            <b-form-group
              id="input-group-2"
              label="Tipo de Veículo"
              label-for="input-2"
            >
              <b-form-select
                v-model="form.idTipoFipe"
                :options="tiposFipe"
                name="Tipo de Veículo"
                v-validate="{ required: true }"
                :state="validateState('Tipo de Veículo')"
                @change="selecionaTipoFipe(), preencheMarca()"
                ref="iptTipoVeiculo"
              ></b-form-select>
            </b-form-group>

            <!-- Input a substituir o select Grupo de Veículo -->
            <b-form-group
              id="input-group-2"
              label="Grupo do Veículo"
              label-for="input-2"
            >
              <b-row ref="topPasso4">
                <b-col :cols="placaEncontrada || selecaoDeGruposAtiva ? 9 : 12">
                  <b-form-input
                    id="Grupo do Veículo"
                    name="Grupo do Veículo"
                    v-model="form.idGrupo.valor"
                    v-validate="{ required: true }"
                    :state="validateState('Grupo do Veículo')"
                    :class="validateState('Grupo do Veículo')"
                    :disabled="true"
                    ref="iptGrupoVeiculo"
                  />
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex justify-content-end"
                  v-if="selecaoDeGruposAtiva"
                >
                  <b-button
                    @click="mostrarModalGrupos = true"
                    pill
                    variant="success"
                    ref="btnTrocarGrupo"
                    >{{ acaoBotao }}</b-button
                  >
                </b-col>
              </b-row>
            </b-form-group>
            <!-- Input a substituir o select Grupo de Veículo -->

            <b-card
              class="my-3"
              v-if="permiteImplemento == true || permiteAgregado == true"
            >
              <b-form-group id="input-group-4">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.caminhao"
                  switch
                  :disabled="!form.somenteAgregado"
                  @input="desmarcasomenteAgregado()"
                  >Caminhão (Cavalo / Trator)</b-form-checkbox
                >
              </b-form-group>
              <b-form-group id="input-group-4" v-if="permiteImplemento">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.implemento"
                  switch
                  @input="desmarcasomenteAgregado()"
                  >Implemento</b-form-checkbox
                >
              </b-form-group>
              <b-form-group id="input-group-4" v-if="permiteAgregado">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.agregado"
                  switch
                  @input="desmarcasomenteAgregado()"
                  >Agregado</b-form-checkbox
                >
              </b-form-group>
              <!-- <b-form-group id="input-group-4" v-if="permiteImplemento">
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.somenteAgregado"
                  switch
                  @input="desmarcaRestante()"
                  >Somente Implemento</b-form-checkbox
                >
              </b-form-group> -->
              <b-button
                v-if="form.somenteAgregado || form.implemento"
                pill
                ref="btnAvancar"
                size="lg"
                variant="info"
                class="btn btn-block mb-2"
                :style="`background-color:${temaCor.botaoFormularios};`"
                @click="
                  openModalComplemento(),
                    (tipoConfiguracaoCaminhao = 'implemento')
                "
                >Adicionar Implemento</b-button
              >
              <b-button
                v-if="form.agregado"
                pill
                ref="btnAvancar"
                size="lg"
                variant="info"
                class="btn btn-block mb-2"
                :style="`background-color:${temaCor.botaoFormularios};`"
                @click="
                  openModalComplemento(),
                    (tipoConfiguracaoCaminhao = 'agregado')
                "
                >Adicionar Agregado</b-button
              >
              <b-card
                title="Implementos"
                class="my-3"
                v-if="form.implementos.length > 0"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in form.implementos"
                    :key="item.id"
                  >
                    {{ item.implementoMarca }} - {{ item.implementoModelo }}
                    <b-badge variant="success" class="mr-3">{{
                      item.valorCobertura | currency
                    }}</b-badge>

                    <div class="mt-2 text-right">
                      <button
                        class="btn btn-dark mr-3"
                        type="button"
                        id="button-addon2"
                        @click="atualizarComplemento(item, index)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        id="button-addon2"
                        @click="deleteComplemento(item, index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </b-list-group-item> </b-list-group
              ></b-card>

              <b-card
                title="Agregados"
                class="my-3"
                v-if="form.agregados.length > 0"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in form.agregados"
                    :key="item.id"
                  >
                    {{ item.descricaoAgregado }}
                    <b-badge variant="success" class="mr-3">{{
                      item.valorCobertura | currency
                    }}</b-badge>

                    <div class="mt-2 text-right">
                      <button
                        class="btn btn-dark mr-3"
                        type="button"
                        id="button-addon2"
                        @click="atualizarComplemento(item, index)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        id="button-addon2"
                        @click="deleteComplemento(item, index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </b-list-group-item> </b-list-group
              ></b-card>
            </b-card>

            <b-form-group
              id="Descrição do Agregado"
              label="Descrição do Agregado"
              label-for="Descrição do Agregado"
              v-if="form.somenteAgregado"
            >
              <b-form-input
                id="Descrição do Agregado"
                v-model="form.descricaoAgregado"
                placeholder="Descrição do Agregado"
                name="Descrição do Agregado"
                v-validate="{ required: true }"
                :state="validateState('Descrição do Agregado')"
                :class="validateClass('Descrição do Agregado')"
              ></b-form-input>
            </b-form-group>

            <!-- placa agregado-->
            <b-form-group id="Placa" :label="`Placa`" label-for="Placa">
              <the-mask
                mask="AAA-#XX#"
                style="text-transform: uppercase"
                id="input-2"
                class="form-control"
                v-model="form.placa"
                placeholder="Placa"
                name="Placa"
                :masked="true"
                v-validate="{ length: 8 }"
                :state="validateState('Placa')"
              />
            </b-form-group>

            <!-- chassi agregado-->
            <b-form-group :label="`Chassi`" label-for="input-2">
              <b-form-input
                id="input-2"
                style="text-transform: uppercase"
                v-model="form.chassi"
                placeholder="Chassi"
                name="Chassi"
                maxlength="17"
                @input="chassiToUpperCase(form.chassi)"
                v-validate="{ length: 17 }"
                :state="validateState('Chassi')"
                :class="validateClass('Chassi')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="Placa do Agregado"
              :label="!form.caminhao ? 'Marca' : 'Marca Caminhão / Cavalo'"
              label-for="input-2"
              v-if="!form.somenteAgregado"
            >
              <b-form-select
                v-model="form.veiculo.marca"
                :options="marcas"
                name="Marca"
                v-validate="{ required: true }"
                :state="validateState('Marca')"
                @change="preencheModelo()"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="Marca do Agregado"
              label="Marca do Agregado"
              label-for="Marca do Agregado"
              v-else-if="form.somenteAgregado"
            >
              <b-form-input
                id="Marca do Agregado"
                v-model="form.veiculo.marca"
                placeholder="Marca do Agregado"
                name="Marca do Agregado"
                v-validate="{ required: true }"
                :state="validateState('Marca do Agregado')"
                :class="validateClass('Marca do Agregado')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="!form.caminhao ? 'Modelo' : 'Modelo Caminhão / Cavalo'"
              label-for="input-2"
              v-if="!form.somenteAgregado"
            >
              <b-form-select
                v-model="form.veiculo.modelo"
                :options="modelos"
                name="Modelo"
                v-validate="{ required: true }"
                :state="validateState('Modelo')"
                @change="preencheAnos()"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="Modelo do Agregado"
              label="Modelo do Agregado"
              label-for="Modelo do Agregado"
              v-else-if="form.somenteAgregado"
            >
              <b-form-input
                id="Modelo do Agregado"
                v-model="form.veiculo.modelo"
                placeholder="Modelo do Agregado"
                name="Modelo do Agregado"
                v-validate="{ required: true }"
                :state="validateState('Modelo do Agregado')"
                :class="validateClass('Modelo do Agregado')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="Ano Modelo"
              :label="
                !form.caminhao ? 'Ano Modelo' : 'Ano Modelo Caminhão / Cavalo'
              "
              label-for="Ano Modelo"
              v-if="!form.somenteAgregado"
            >
              <b-form-select
                v-model="form.veiculo.anoGeral"
                :options="anosModelo"
                name="Ano Modelo"
                v-validate="{ required: true }"
                :state="validateState('Ano Modelo')"
                @change="selecionaAnoModelo()"
                key="AnoModelo"
              ></b-form-select>
            </b-form-group>

            <!-- Ano modelo do Agregado -->
            <b-form-group
              id="Ano Modelo"
              label="Ano Modelo Agregado"
              label-for="anoModeloDoAgregado"
              v-else-if="form.somenteAgregado"
            >
              <the-mask
                v-if="form.somenteAgregado"
                type="text"
                class="form-control"
                v-model="form.veiculo.anoModelo"
                v-validate="{
                  required: true,
                  date_format: 'yyyy',
                  max_value: new Date().getFullYear(),
                }"
                placeholder="Ano modelo do agregado"
                mask="####"
                id="anoModeloDoAgregado"
                name="Ano modelo do agregado"
                :state="validateState('Ano modelo do agregado')"
                :class="validateClass('Ano modelo do agregado')"
              ></the-mask>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="
                !form.caminhao
                  ? 'Ano Fabricação'
                  : 'Ano Fabricação Caminhão / Cavalo'
              "
              label-for="input-2"
              v-if="!form.somenteAgregado"
            >
              <b-form-select
                v-model="form.veiculo.anoFabricacao"
                :options="anosFabricacao"
                name="Ano Fabricação"
                @change="
                  buscaDadosFipe(
                    'ConsultarValorComTodosParametros',
                    'form.veiculo.resultFipe',
                    '',
                    '',
                  ),
                    preencheGruposSemPlacaEncontrada()
                "
                v-validate="{ required: true }"
                :state="validateState('Ano Fabricação')"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="Ano fabricação"
              label="Ano Fabricação Agregado"
              label-for="AnofabricacaoAgregado"
              v-else-if="form.somenteAgregado"
            >
              <the-mask
                v-if="form.somenteAgregado"
                type="text"
                class="form-control"
                v-model="form.veiculo.anoFabricacao"
                v-validate="{
                  required: true,
                  date_format: 'yyyy',
                  max_value: new Date().getFullYear(),
                }"
                placeholder="Ano Fabricação do Agregado"
                mask="####"
                id="AnofabricacaoAgregado"
                name="Ano de fabricacao do agregado"
                :state="validateState('Ano de fabricacao do agregado')"
                :class="validateClass('Ano de fabricacao do agregado')"
              ></the-mask>
            </b-form-group>

            <b-form-group v-if="exibeCilindrada == true" label="Cilindradas">
              <b-form-input
                type="number"
                v-model="form.veiculo.cilindradas"
                v-validate="{ required: true }"
                placeholder="Cilindradas"
                id="Cilindradas"
                name="Cilindradas"
                :state="validateState('Cilindradas')"
              ></b-form-input>
            </b-form-group>
            <div class="btnDiv">
              <b-button
                pill
                size="lg"
                variant="info"
                class="btn btn-block"
                @click="limpaCampos"
                >Limpar Modelo</b-button
              >
            </div>
          </div>
          <b-card title="Valores de Cobertura">
            <b-form-group
              id="input-group-2"
              label="Preço FIPE"
              label-for="input-2"
            >
              <money
                class="form-control"
                name="Preço FIPE"
                id="Preço FIPE"
                placeholder="Preço FIPE"
                v-model="form.precoVeiculo"
                v-validate="{ required: true, min_value: 0.01 }"
                :state="validateState('Preço FIPE')"
                :danger="validateClass('Preço FIPE')"
                v-bind="money"
                :disabled="!form.somenteAgregado ? true : false"
              ></money>
            </b-form-group>

            <b-form-group
              v-if="form.agregado || form.implemento"
              id="input-group-2"
              label="Valor total do conjunto"
              label-for="input-2"
            >
              <money
                class="form-control"
                name="Valor total do conjunto"
                id="Valor total do conjunto"
                placeholder="Valor da Nota"
                v-model="form.valorTotalConjunto"
                v-validate="{ required: true, min_value: 0.01 }"
                :state="validateState('Valor total do conjunto')"
                :danger="validateClass('Valor total do conjunto')"
                v-bind="money"
                :disabled="true"
              ></money>
            </b-form-group>

            <b-form-group
              id="Valor de Cobertura"
              label="Valor de Cobertura"
              label-for="Valor de Cobertura"
            >
              <money
                class="form-control"
                name="Valor de Cobertura"
                id="Valor de Cobertura"
                placeholder="Valor da Nota"
                v-model="form.valorCobertura"
                v-validate="{ required: true, min_value: 0.01 }"
                :state="validateState('Valor de Cobertura')"
                :danger="validateClass('Valor de Cobertura')"
                v-bind="money"
                :disabled="true"
              ></money>
            </b-form-group>
          </b-card>
        </div>
        <div v-if="passo == 5">
          <b-card
            :class="`${form.agregado ? 'card-visivel my-2' : 'card-invisivel'}`"
          >
            <h5 class="mb-4 text-center">Veículo {{ this.form.placa }}</h5>
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="form.produtosSelecionados"
              name="flavour-2"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="item in produtosObrigatorios"
                  :key="item.id_produto"
                >
                  <div class="ContainerPai">
                    <b-form-checkbox
                      class="w-100 mb-3"
                      :value="{
                        id: item.id_produto,
                      }"
                      :disabled="validaSelecionado(item)"
                      @click.native.prevent="selecionaProduto(item)"
                      size="lg"
                    >
                      <b class="mr-2">{{ item.nomeProduto }}</b>
                      <span
                        class="mr-2 valorprod"
                        v-if="item.visivelProposta"
                        :style="item.style"
                        >{{ item.valor | currency }}</span
                      >
                      <span
                        class="valorprod"
                        v-if="
                          item.descontoConcedido > 0 && item.visivelProposta
                        "
                        >{{ item.valorFinal | currency }}</span
                      >
                      <span
                        class="valorprod"
                        v-if="item.ismensal && item.visivelProposta"
                        >/mês</span
                      >
                    </b-form-checkbox>
                    <div class="ContainerPai">
                      <button
                        v-if="item.alterarValor && permissaoAlterarValorProduto"
                        type="button"
                        @click="abrirModalNovoValorProduto(item)"
                        class="btn btn-success mr-2"
                      >
                        +
                      </button>
                      <button
                        v-if="
                          item.porcentagemDescontoMaxima && permissaoDarDesconto
                        "
                        type="button"
                        @click="exibePorcentagemDesconto(item)"
                        class="btn btn-dark"
                      >
                        %
                      </button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-form-checkbox-group>

            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="form.produtosSelecionados"
              name="flavour-2"
              class="mt-4 mb-4"
            >
              <b-list-group>
                <b-list-group-item>
                  <h5
                    v-if="produtosNaoObrigatorios.length > 0"
                    class="my-2 text-center pt-2 pb-3"
                  >
                    Opcionais
                  </h5>
                </b-list-group-item>
                <b-list-group-item
                  v-for="item in produtosNaoObrigatorios"
                  :key="item.id_produto"
                  ><div class="ContainerPai">
                    <b-form-checkbox
                      class="w-100 mb-3"
                      :value="{
                        id: item.id_produto,
                      }"
                      @click.native.prevent="selecionaProduto(item)"
                      size="lg"
                    >
                      <b class="mr-2">{{ item.nomeProduto }}</b>
                      <span
                        class="mr-2 valorprod"
                        v-if="item.visivelProposta"
                        :style="item.style"
                        >{{ item.valor | currency }}</span
                      >
                      <span
                        class="valorprod"
                        v-if="
                          item.descontoConcedido > 0 && item.visivelProposta
                        "
                        >{{ item.valorFinal | currency }}</span
                      >
                      <span
                        class="valorprod"
                        v-if="item.ismensal && item.visivelProposta"
                        >/mês</span
                      >
                    </b-form-checkbox>
                    <div class="ContainerPai">
                      <button
                        v-if="item.alterarValor && permissaoAlterarValorProduto"
                        type="button"
                        @click="abrirModalNovoValorProduto(item)"
                        class="btn btn-success mr-2"
                      >
                        +
                      </button>
                      <button
                        v-if="
                          item.porcentagemDescontoMaxima && permissaoDarDesconto
                        "
                        type="button"
                        @click="exibePorcentagemDesconto(item)"
                        class="btn btn-dark"
                      >
                        %
                      </button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-form-checkbox-group>
            <h6>
              Total Mensal:
              <span class="valorprod">{{
                somaValorTotalMes(form.produtosSelecionados, [
                  ...produtosObrigatorios,
                  ...produtosNaoObrigatorios,
                ]) | currency
              }}</span>
            </h6>
            <h6 class="mb-5">
              Total à vista:
              <span class="valorprod">{{
                somaValorTotal(form.produtosSelecionados, [
                  ...produtosObrigatorios,
                  ...produtosNaoObrigatorios,
                ]) | currency
              }}</span>
            </h6>
          </b-card>
          <!-- agregados -->
          <div v-if="form.agregado" class="mb-5">
            <b-card
              class="mt-4"
              v-for="(agregado, id) in form.agregados"
              :key="`agregado${id}`"
            >
              <h5 class="mb-4 text-center">
                {{
                  agregado.placa
                    ? agregado.placa + ' - '
                    : '' || agregado.chassi
                    ? agregado.chassi + ' - '
                    : ''
                }}
                {{ agregado.descricao }}
              </h5>
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="form.agregados[id].produtosSelecionados"
                name="flavour-2"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="prodObrigatorio in agregado.produtosObrigatorios"
                    :key="`prodObrig-${prodObrigatorio.id_produto}`"
                    ><div class="ContainerPai">
                      <b-form-checkbox
                        class="w-100 mb-3"
                        :value="{
                          id: prodObrigatorio.id_produto,
                        }"
                        :disabled="
                          validaSelecionadoAgregado(prodObrigatorio, id)
                        "
                        @click.native.prevent="
                          selecionaProdutoAgregado(prodObrigatorio, id)
                        "
                        size="lg"
                      >
                        <b class="mr-2">{{ prodObrigatorio.nomeProduto }}</b>
                        <span
                          class="mr-2 valorprod"
                          v-if="prodObrigatorio.visivelProposta"
                          :style="prodObrigatorio.style"
                          >{{ prodObrigatorio.valor | currency }}</span
                        >
                        <span
                          class="valorprod"
                          v-if="
                            prodObrigatorio.descontoConcedido > 0 &&
                            prodObrigatorio.visivelProposta
                          "
                          >{{ prodObrigatorio.valorFinal | currency }}</span
                        >
                        <span
                          class="valorprod"
                          v-if="
                            prodObrigatorio.ismensal &&
                            prodObrigatorio.visivelProposta
                          "
                          >/mês</span
                        >
                      </b-form-checkbox>
                      <div>
                        <button
                          v-if="
                            prodObrigatorio.alterarValor &&
                            permissaoAlterarValorProduto
                          "
                          type="button"
                          @click="abrirModalNovoValorProduto(item)"
                          class="btn btn-success mr-2"
                        >
                          +
                        </button>
                        <button
                          v-if="
                            prodObrigatorio.porcentagemDescontoMaxima &&
                            permissaoDarDesconto
                          "
                          type="button"
                          @click="exibePorcentagemDesconto(prodObrigatorio)"
                          class="btn btn-dark"
                        >
                          %
                        </button>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-form-checkbox-group>

              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="form.agregados[id].produtosSelecionados"
                name="flavour-2"
                class="mt-4 mb-4"
              >
                <b-list-group
                  v-if="agregado.produtosNaoObrigatorios.length > 0"
                >
                  <b-list-group-item>
                    <h5 class="my-2 text-center pt-2 pb-3">Opcionais</h5>
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="prodNaoObrigatorio in agregado.produtosNaoObrigatorios"
                    :key="`prodNObrig-${prodNaoObrigatorio.id_produto}`"
                  >
                    <div class="ContainerPai">
                      <b-form-checkbox
                        class="w-100 mb-3"
                        :value="{
                          id: prodNaoObrigatorio.id_produto,
                        }"
                        @click.native.prevent="
                          selecionaProdutoAgregado(prodNaoObrigatorio, id)
                        "
                        size="lg"
                      >
                        <b class="mr-2">{{ prodNaoObrigatorio.nomeProduto }}</b>
                        <span
                          class="mr-2 valorprod"
                          v-if="prodNaoObrigatorio.visivelProposta"
                          :style="prodNaoObrigatorio.style"
                          >{{ prodNaoObrigatorio.valor | currency }}</span
                        >
                        <span
                          class="valorprod"
                          v-if="
                            prodNaoObrigatorio.descontoConcedido > 0 &&
                            prodNaoObrigatorio.visivelProposta
                          "
                          >{{ prodNaoObrigatorio.valorFinal | currency }}</span
                        >
                        <span
                          class="valorprod"
                          v-if="
                            prodNaoObrigatorio.ismensal &&
                            prodNaoObrigatorio.visivelProposta
                          "
                          >/mês</span
                        >
                      </b-form-checkbox>
                      <div class="ContainerPai">
                        <button
                          v-if="
                            prodNaoObrigatorio.alterarValor &&
                            permissaoAlterarValorProduto
                          "
                          type="button"
                          @click="abrirModalNovoValorProduto(item)"
                          class="btn btn-success mr-2"
                        >
                          +
                        </button>
                        <button
                          v-if="
                            prodNaoObrigatorio.porcentagemDescontoMaxima &&
                            permissaoDarDesconto
                          "
                          type="button"
                          @click="exibePorcentagemDesconto(prodNaoObrigatorio)"
                          class="btn btn-dark"
                        >
                          %
                        </button>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-form-checkbox-group>
              <h6>
                Total Mensal:
                <span class="valorprod">{{
                  somaValorTotalMes(form.agregados[id].produtosSelecionados, [
                    ...agregado.produtosObrigatorios,
                    ...agregado.produtosNaoObrigatorios,
                  ]) | currency
                }}</span>
              </h6>
              <h6>
                Total à vista:
                <span class="valorprod">{{
                  somaValorTotal(form.agregados[id].produtosSelecionados, [
                    ...agregado.produtosObrigatorios,
                    ...agregado.produtosNaoObrigatorios,
                  ]) | currency
                }}</span>
              </h6>
            </b-card>
          </div>
          <!-- Observação geral -->
          <b-form-group
            class="mt-5"
            label="Observação Geral"
            label-for="input-2"
          >
            <b-form-textarea
              id="input-2"
              v-model="form.observacao"
              placeholder=""
              name="Observação Geral"
              :state="validateState('Observação Geral')"
              :class="validateClass('Observação Geral')"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div v-if="passo == 6">
          <div v-if="resultNegativo == true">
            <div class="text-center mb-2">
              <font-awesome-icon icon="times" class="iconeErro" />
              <h4 class="mt-3 mb-5">Atenção</h4>
            </div>

            <span>
              Consultor, algo deu errado em sua cotação. Veja abaixo o que
              aconteceu para realizar a correção.
              <br />
              <br />
              <div class="alert alert-danger">{{ msgNegativo }}</div>
            </span>
          </div>
          <div v-if="resultNegativo == false">
            <div class="text-center">
              <font-awesome-icon :icon="['fa', 'check']" class="iconeSucesso" />
            </div>
            <h4>Parabéns</h4>
            <span>Consultor, sua cotação foi processada com sucesso</span>
          </div>
        </div>

        <b-row class="mt-5">
          <b-col v-if="passo != 0 && passo != 6" cols="2">
            <b-button variant="link text-dark" @click="voltar">
              <font-awesome-icon :icon="['fa', 'arrow-left']" />
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-if="passo != 6"
              pill
              :disabled="btnDisabled"
              ref="btnAvancar"
              size="lg"
              type="submit"
              variant="info"
              class="btn btn-block"
              :style="`background-color:${temaCor.botaoFormularios};`"
              >Avançar</b-button
            >
            <b-button
              v-else
              pill
              ref="btnAvancar"
              size="lg"
              type="submit"
              variant="info"
              class="btn btn-block"
              :style="`background-color:${temaCor.botaoFormularios};`"
              >Concluir</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <b-modal
      v-model="openEscolherAssociado"
      hide-footer
      centered
      title="Atenção!"
    >
      <p class="alert alert-warning">
        Foram encontrados um ou mais associados / prospects com esse email, você
        pode escolher um para continuar ou clicar em nenhum destes e criar um
        novo registro!
      </p>
      <b-list-group>
        <b-list-group-item
          v-for="item in associadoEncontrado"
          :key="item.id"
          :variant="`${item.situacao != 'INATIVO' ? '' : 'danger'}`"
        >
          <b-row>
            <b-col>
              <label>NOME:</label> {{ item.nome }}<br />
              <label>CELULAR:</label>{{ item.celular }}<br />
              <label>SITUAÇÃO:</label
              >{{ item.situacao == null ? 'Prospect' : item.situacao }}
            </b-col>
            <b-col cols="2" class="text-center">
              <button
                @click="selecionaPorEmail(item)"
                class="btn btn-success"
                type="button"
                id="button-addon2"
              >
                <font-awesome-icon :icon="['fa', 'check']" />
              </button>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item variant="danger">
          <b-row>
            <b-col class="text-center">Nenhum destes</b-col>
            <b-col cols="2" class="text-center">
              <button
                @click="selecionaPorEmail({})"
                class="btn btn-success"
                type="button"
                id="button-addon2"
              >
                <font-awesome-icon :icon="['fa', 'check']" />
              </button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <!-- Modal Implemento -->
    <b-modal
      v-model="modalComplemento"
      hide-footer
      centered
      :title="`Dados do ${tipoConfiguracaoCaminhao}`"
    >
      <FormComplemento
        :temaCor="temaCor"
        :formCompleto="false"
        :dados="complementoParaEditar"
        :contModal="contModal"
        :grupos="gruposAgregados"
        :tipoComplemento="tipoConfiguracaoCaminhao"
        @concluido="adicionarComplemento"
      ></FormComplemento>
    </b-modal>

    <b-modal
      v-model="modalDesconto"
      :active.sync="modalDesconto"
      hide-footer
      centered
      title="Desconto"
    >
      <FormDesconto
        :temaCor="temaCor"
        :dados="itens"
        :modal="modalDesconto"
        @salvarDesconto="salvarDesconto"
      ></FormDesconto>
    </b-modal>
    <!-- START MODAL GRUPO -->
    <b-modal
      v-model="mostrarModalGrupos"
      :active.sync="mostrarModalGrupos"
      hide-footer
      centered
      title="Grupo do Veículo"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(grupo, index) in tiposVeiculos"
          :key="index"
          :variant="destacaGrupo(grupo)"
        >
          <div class="ContainerPai">
            <b-col cols="1 ContainerRadio">
              <b-form-radio
                v-model="form.idGrupo"
                :value="grupo.value"
                @change="selecionaTipo(), preencheMarca(), fecharModalGrupos()"
              >
              </b-form-radio>
            </b-col>
            <b-col cols="11">
              <b-col class="grupoNome">{{ grupo.text }}</b-col>
              <b-col>{{ grupo.descricao }}</b-col>
            </b-col>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <!-- END MODAL GRUPO -->

    <!-- START MODAL PLACA RECUSADA -->
    <b-modal
      v-model="modalPlacaRecusada"
      :active.sync="modalPlacaRecusada"
      hide-header
      ok-only
      centered
      :title="`${situacaoPlacaModaTitle}`"
    >
      <b-alert show variant="danger">{{ situacaoPlacaModaTitle }}</b-alert>

      <!-- <div class="ml-2">Encontramos uma cotação para essa placa:</div> -->
      <div class="ml-2">{{ messagemPlacaRecusada }}</div>

      <ul class="mt-2 mb-2">
        <li v-if="form.placa">
          <b
            ><strong
              >Placa:
              {{ form.placa ? form.placa.toUpperCase() : form.placa }}</strong
            ></b
          >
        </li>
        <li v-if="regionalConsultorPlaca">
          <b
            >Regional: <strong>{{ regionalConsultorPlaca }}</strong>
          </b>
        </li>
        <li v-if="nomeConsultorPlaca">
          <b
            >consultor: <strong>{{ nomeConsultorPlaca }}</strong></b
          >
        </li>
      </ul>

      <div v-if="codigoSituacaoPlaca === '999'" class="ml-2">
        Informe outra placa para dar continuidade
      </div>
    </b-modal>
    <!-- END MODAL GRUPO -->

    <b-modal
      v-model="mostrarModalNovoValorProduto"
      :active.sync="mostrarModalNovoValorProduto"
      hide-footer
      centered
      title="Novo Valor do Produto"
    >
      <FormNovoValorProduto
        v-if="mostrarModalNovoValorProduto"
        :produto="novoValorProduto"
        @close="mostrarModalNovoValorProduto = false"
      ></FormNovoValorProduto>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import locale from './../locale';
import service from '../services';
import utils from '../services/utils';
import topoInterno from './TopoInterno';
import { Money } from 'v-money';
import colors from './../services/colors';
import MenuLateral from './MenuLateral';
import FormComplemento from './FormComplemento.vue';
import FormAgregado from './FormAgregado.vue';
import FormDesconto from './FormDesconto.vue';
import FormNovoValorProduto from './FormNovoValorProduto.vue';
import { consultaPlaca, consultarPlacaChassiBaseBP } from '../services/cotacao';

export default {
  name: 'NovaCotacao',
  props: {
    msg: String,
  },
  watch: {
    deep: true,
    isCarroZero: function (newValue, oldValue) {
      this.form.isZero = newValue;
      this.verificaZero();
    },
    cep: function (newValue, oldValue) {
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
    'form.precoVeiculo': function (value) {
      if (value && this.form.somenteAgregado) {
        this.form.valorTotalCobertura = value;
        this.form.valorCobertura = value;
      }
    },
    'form.placa': function (value) {
      if (value && value.length <= 7) {
        this.btnDisabled = false;
      }
    },
  },
  data() {
    return {
      idTipoFipeAux: null,
      gruposAux: null,
      desabilitarInputVeiculoZero: false,
      propostaParaSomenteAgregado: false,
      btnVeiculoZeroHabilitado: false,
      utils: utils,
      gruposAgregados: [],
      tipoConfiguracaoCaminhao: null,
      idPreCotacao: null,
      tiposFipeAux: [],
      alterarGrupoVeiculoCotacao: null,
      habilitaAlterarGrupo: false,
      contModal: 0,
      permitirRestricoes: false,
      modalComplemento: false,
      modalAgregado: false,
      modalDesconto: false,
      agregadoToEdit: {},
      agregadoToEditIndex: null,
      complementoParaEditar: {},
      indexComplemento: null,
      produtoToDesconto: null,
      decoded: null,
      openEscolherAssociado: false,
      menuOpen: false,
      itens: null,
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      encontrouCep: false,
      isGrupoModelos: false,
      btnDisabled: false,
      tiposProposta: [
        { text: 'Selecione', value: null },
        'Adesão',
        'Reativação',
        'Substituição',
        'Inclusão',
        'Troca de Titularidade',
        'Migração',
      ],
      tiposPropostaReduzido: [
        { text: 'Selecione', value: null },
        'Reativação',
        'Troca de Titularidade',
      ],
      tiposPessoas: [
        { text: 'Selecione', value: null },
        'Pessoa Física',
        'Pessoa Jurídica',
      ],
      resultNegativo: false,
      msgNegativo: null,
      date: null,
      exibeCilindrada: false,
      carregando: false,
      passo: 0,
      codigoTabelaReferencia: null,
      tiposVeiculos: [],
      tiposFipe: [],
      marcas: [],
      modelos: [],
      modelosAux: [],
      anosModelo: [],
      anosFabricacao: [],
      estados: [],
      cidades: [],
      grupos: [],
      produtos: [],
      restricoes: [],
      regionais: [{ text: null, label: 'Selecione' }],
      divisoes: [{ text: null, label: 'Selecione' }],
      usuarios_regionais: [],
      exibeCidade: false,
      isCarroZero: false,
      produtosObrigatorios: [],
      produtosNaoObrigatorios: [],
      cep: null,
      formAssociadoEncontrado: {},
      associadoEncontrado: [],
      dadosPlaca: null,
      placaEncontrada: false,
      modelosFipeEncontrados: [],
      modeloFipeSelecionado: null,
      fipeEncontrada: false,
      permiteImplemento: false,
      permiteAgregado: false,
      permiteSomenteAgregado: false,
      permiteAlterarRegional: false,
      selecaoDeGruposAtiva: false,
      form: {
        precoVeiculo: null,
        descricaoAgregado: null,
        origemCadastroProposta: window.location,
        idProspect: null,
        idUsuario: 7,
        idEmpresa: null,
        permiteInvoiceSelfService: true,
        origem: 'Network',
        tipoProposta: 'Adesão',
        cotaTabela: null,
        idTabelaValor: null,
        placa: '',
        isZero: false,
        chassi: '',
        nf: null,
        valorNf: '',
        dataNf: null,
        tipoPessoa: null,
        nome: null,
        telefone: null,
        celular: null,
        email: null,
        cep: null,
        endereco: null,
        bairro: null,
        cidade: null,
        estado: null,
        pais: null,
        observacao: null,
        idGrupo: { id: null, valor: null },
        caminhao: false,
        implemento: false,
        agregado: false,
        somenteAgregado: false,
        implementos: [],
        agregados: [],
        valorTotalConjunto: 0,
        valorTotalCobertura: 0,
        valorCobertura: 0,
        regional: { id: null },
        divisao: { id: null },
        idTipoFipe: null, //form.idTipoFipe
        veiculo: {
          tipoVeiculo: null,
          codigoMarca: null,
          codigoModelo: null,
          anoGeral: null,
          anoModelo: null,
          anoFabricacao: null,
          cilindradas: null,
          codigoTipoCombustivel: null,
          combustivel: '',
          marca: { id: null, valor: null },
          modelo: { id: null, valor: null },
          codigoFipe: '',
          ano: null,
          resultFipe: {},
          tipoCambio: null,
          numeroMotor: null,
        },
        produtosSelecionados: [],
        produtosSelecionadosAux: [],
        restricoesSelecionadas: [],
      },
      temaCor: {},
      show: true,
      grupoSelecionado: {},
      grupoForaDaBuscaInicial: {},
      dicionarioChassisNacionais: [],
      dicionarioTiposVeiculos: {},
      mostrarModalGrupos: false,
      acaoBotao: 'Escolher',
      placaValida: true,
      consultorCelular: null,
      novoValorProduto: {},
      mostrarModalNovoValorProduto: false,
      idPerfil: null,
      userPermissoes: [],
      formasPagamento: null,
      formaPagamentoPadraoEntrada: null,
      formaPagamentoPadraoMensal: null,
      permitePlacaRepetida: false,
      modalPlacaRecusada: false,
      regionalConsultorPlaca: null,
      nomeConsultorPlaca: null,
      codigoSituacaoPlaca: '0',
      situacaoPlacaModaTitle: '',
      messagemPlacaRecusada: '',
      buscarPlacaNovamente: false,
      iptTipoVeiculo: this.$refs.iptTipoVeiculo,
    };
  },
  computed: {
    permissaoDarDesconto() {
      const bemProtege = this.verificaEmpresa();
      const permissaoDarDesconto = this.userPermissoes.find(
        (p) =>
          p.action === 'Pode oferecer desconto' && p.subject === 'Cotações',
      );
      if (bemProtege) {
        return permissaoDarDesconto;
      } else {
        return true;
      }
    },
    permissaoAlterarValorProduto() {
      const bemProtege = this.verificaEmpresa();
      const permissaoAlterarValorProduto = this.userPermissoes.find(
        (p) => p.action === 'Alterar Valor Produto' && p.subject === 'Cotações',
      );
      if (bemProtege) {
        return permissaoAlterarValorProduto;
      } else {
        return true;
      }
    },
    tipoPropostaSelector() {
      return this.codigoSituacaoPlaca === '998'
        ? this.tiposPropostaReduzido
        : this.tiposProposta;
    },
  },
  methods: {
    formataMoedaFipe(valor) {
      if (valor) {
        let currency = valor;
        let regex = /([+-]?[0-9|^.|^,]+)[\.|,]([0-9]+)$/gim;
        let result = regex.exec(currency);

        if (result === null) return valor;

        let floatResult = result
          ? result[1].replace(/[.,]/g, '') + '.' + result[2]
          : currency.replace(/[^0-9-+]/g, '');

        return parseFloat(floatResult);
      } else {
        return 0;
      }
    },
    atualizaDadosValores() {
      const temDadosFipe = !utils.isObjectEmpty(this.form.veiculo.resultFipe);

      const temPrecoFipe = temDadosFipe
        ? this.form.veiculo.resultFipe.preco
        : '0';

      const precoFIPE = temPrecoFipe
        ? parseFloat(
            this.form.veiculo.resultFipe.preco
              .replace('R$ ', '')
              .replace('.', ''),
          )
        : 0;

      const temValorNf = this.form.valorNf;
      const valorNF = temValorNf ? this.form.valorNf : 0;

      const veiculoZero = this.form.isZero;
      //const preco = veiculoZero && this.verificaEmpresa() ? precoFIPE : valorNF;
      let preco = precoFIPE;

      if (veiculoZero && !this.verificaEmpresa()) {
        preco = valorNF;
      }

      this.form.valorNf =
        veiculoZero && this.verificaEmpresa() ? precoFIPE : valorNF;

      let totalValorImplemento = 0;
      let valorTotalConjunto = 0;

      this.form.implementos.forEach((implemento) => {
        const { valorCobertura, valorNf } = implemento;

        totalValorImplemento += valorNf > 0 ? valorNf : valorCobertura;
      });

      //
      this.form.precoVeiculo = preco;
      valorTotalConjunto = preco + totalValorImplemento;
      this.form.valorTotalConjunto = valorTotalConjunto;
      this.form.valorCobertura =
        !this.form.somenteAgregado && this.form.veiculo.tipoVeiculo === 3
          ? valorTotalConjunto
          : preco;
    },
    openModalComplemento() {
      this.modalComplemento = true;

      this.contModal = this.contModal + 1;
      this.complementoParaEditar = {};
      this.indexComplemento = null;
    },
    atualizaAgregado(item, index) {
      this.agregadoToEdit = item;
      this.agregadoToEditIndex = index;
      this.agregadoToEdit.update = true;
      this.modalAgregado = true;
      this.contModal = this.contModal + 1;
    },
    deleteAgregado(item, index) {
      this.form.agregados.splice(index, 1);
      this.atualizaDadosValores();
    },
    atualizarComplemento(item, index) {
      this.contModal = this.contModal + 1;
      this.complementoParaEditar = item;
      this.indexComplemento = index;
      this.complementoParaEditar.update = true;
      this.modalComplemento = true;
    },
    deleteComplemento(item, index) {
      const tipo = `${item.tipo}s`;

      this.form[tipo].splice(index, 1);
      this.atualizaDadosValores();
    },

    adicionaAgregado(obj) {
      this.modalAgregado = false;
      if (this.agregadoToEditIndex != null) {
        this.form.agregados[this.agregadoToEditIndex] = obj;
      } else this.form.agregados.push(obj);

      this.agregadoToEditIndex = null;
      this.atualizaDadosValores();
    },
    adicionarComplemento(obj) {
      const tipo = `${obj.tipo}s`;

      this.modalComplemento = false;

      if (this.indexComplemento != null) {
        this.form[tipo][this.indexComplemento] = obj;
        this.indexComplemento = null;
        this.atualizaDadosValores();

        return;
      }

      this.form[tipo].push(obj);
      this.atualizaDadosValores();
    },
    desmarcaRestante() {
      if (this.form.somenteAgregado) {
        this.form.caminhao = false;
        this.form.agregado = false;
        this.form.implemento = false;
      }
    },
    desmarcasomenteAgregado() {
      if (this.form.caminhao || this.form.agregado || this.form.implemento) {
        this.form.somenteAgregado = false;

        //if (this.form.caminhao == false) this.form.caminhao = true;
      }
      if (!this.form.caminhao && (this.form.agregado || this.form.implemento))
        this.form.caminhao = true;
    },
    buscaEmail() {
      if (this.form.email.length > 4)
        return new Promise(async (resolve, reject) => {
          this.carregando = true;

          service
            .post('Associado', `validaAssociado`, this.form)
            .then((res) => {
              var arrayRes = res.data;
              if (res.data.length > 0) {
                this.associadoEncontrado = res.data;
                this.openEscolherAssociado = true;
              } else {
                this.form.tipoPessoa = 'Pessoa Física';
                this.form.nome = null;
                this.form.telefone = null;
                this.form.celular = null;
                this.cep = null;
                this.form.cep = null;
                this.form.endereco = null;
                this.form.bairro = null;
                this.form.cidade = null;
                this.form.estado = null;
                this.form.pais = null;
                this.form.preenchidoAutomatico = false;
                this.form.idProspect = null;
              }

              this.carregando = false;
              resolve();
            });
        });
    },
    selecionaPorEmail(item) {
      this.form.tipoPessoa = item.tipo;
      this.form.nome = item.nome;
      this.form.telefone = item.telefone;
      this.form.celular = item.celular;
      this.cep = item.cep;
      this.form.cep = item.cep;
      this.form.endereco = item.endereco;
      this.form.bairro = item.bairro;
      this.form.cidade = item.cidade;
      this.form.estado = item.estado;
      this.form.pais = item.pais;
      this.form.preenchidoAutomatico = true;
      this.form.idProspect = item.idProspect;
      this.openEscolherAssociado = false;
    },
    exibePorcentagemDesconto(modelosFipeEncontrados) {
      this.produtoToDesconto = modelosFipeEncontrados;
      this.modalDesconto = true;
      if (this.modalDesconto == true) {
        this.itens = modelosFipeEncontrados;
      }
      /*
      var aux = this.produtos.find((x) => x.id_produto == item.id_produto);
      if (aux.isDesconto) aux.isDesconto = !aux.isDesconto;
      else aux.isDesconto = true;
      this.produtosObrigatorios = this.produtos.filter(
        (x) => x.obrigatorio == true
      );
      this.produtosNaoObrigatorios = this.produtos.filter(
        (x) => x.obrigatorio != true
      );
      */
      //
    },
    salvarDesconto(item) {
      if (item.valorDesconto > 0) {
        item.style = 'text-decoration: line-through;';
      } else item.style = null;

      this.produtoToDesconto = null;
      this.modalDesconto = false;
    },
    verificaZero() {
      if (this.form.isZero == true) {
        this.form.placa = null;
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    verificaPasso() {
      let arrPassos = [
        'Regional',
        'Consulta',
        'Dados Pessoais',
        'Restrições',
        'Dados do Veículo',
        'Produtos',
        'Resultado',
      ];
      return arrPassos[this.passo];
    },
    voltar() {
      this.passo = this.passo - 1;
      this.btnDisabled = false;
    },
    selecionaTipo() {
      var aux = this.grupos.find((x) => x.idGrupo == this.form.idGrupo.id);
      if (!aux) {
        aux = this.grupoForaDaBuscaInicial;
      }
      this.form.veiculo.tipoVeiculo = aux.idTipoFipe;
      if (aux.idTipoFipe == 2) {
        this.exibeCilindrada = true;
      } else {
        this.exibeCilindrada = false;
      }

      this.permiteImplemento = aux.permiteImplemento;
      this.permiteAgregado = aux.permiteAgregado;

      this.permiteSomenteAgregados = aux.permiteSomenteAgregado;

      if (this.permiteSomenteAgregados) {
        this.form.somenteAgregado = true;
        this.form.veiculo.marca = null;
        this.form.veiculo.modelo = null;
        this.form.veiculo.resultFipe = null;

        this.form.veiculo.resultFipe = {
          tipo: null,
          fipe_codigo: '',
          id_marca: null,
          marca: null,
          id_modelo: null,
          modelo: null,
          ano: null,
          name: null,
          combustivel: null,
          preco: null,
          ano_modelo: null,
        };
      }

      if (aux.idTipoFipe == 3) {
        this.form.caminhao = true;
        this.gruposAgregados = aux.gruposAgregados
          ? JSON.parse(aux.gruposAgregados)
          : null;
      }
    },
    async preencheMarca() {
      if (this.form.somenteAgregado) {
        this.form.veiculo.marca = null;
        this.form.veiculo.modelo = null;
        return;
      }

      if (!this.placaEncontrada) {
        await this.buscaMarcasPorTipoVeiculo(this.form.idTipoFipe);
      } else {
        this.form.veiculo.marca = { id: null, valor: null };
        this.form.veiculo.modelo = { id: null, valor: null };
        this.marcas = [];
        this.modelos = [];
        this.anosModelo = [];
        this.anosFabricacao = [];
        this.form.veiculo.anoGeral = { id: null, valor: null };
        this.form.veiculo.anoFabricacao = null;
        this.form.veiculo.anoModelo = null;

        service
          .post('Cotacao', 'ConsultaMarcasModeloPorGrupo', this.form)
          .then((res) => {
            if (res.data.length > 0) {
              this.isGrupoModelos = true;
              this.marcas = [];
              this.modelosAux = [];

              res.data.forEach((element) => {
                const aux = this.marcas.filter(
                  (x) => x.value.id == element.idMarca,
                );
                const auxModelos = this.modelosAux.filter(
                  (y) => y.value.id == element.idModelo,
                );
                if (aux.length == 0)
                  this.marcas.push({
                    value: {
                      id: element.idMarca,
                      valor: element.marca,
                      isGrupoModelos: true,
                    },
                    text: element.marca,
                  });

                if (auxModelos.length == 0)
                  this.modelosAux.push({
                    idMarca: element.idMarca,
                    idTipo: element.idTipo,
                    value: {
                      id: element.idModelo,
                      valor: element.modelo,
                      isGrupoModelos: true,
                    },
                    text: element.modelo,
                  });
              });
              this.buscaDadosFipe('ConsultarMarcas', 'marcas', 'name', 'id');
              this.marcas.unshift({ id: null, text: 'Selecione' });
            } else {
              this.isGrupoModelos = false;
              this.buscaDadosFipe('ConsultarMarcas', 'marcas', 'name', 'id');
            }
          });
      }
    },
    preencheModelo() {
      if (!this.placaEncontrada) {
        this.modelos = [];
        this.anosModelo = [];
        this.anosFabricacao = [];

        this.form.veiculo.codigoMarca = this.form.veiculo.marca.id;
        this.form.veiculo.codigoModelo = null;

        this.anosModelo = [];
        this.anosFabricacao = [];
        this.form.veiculo.anoGeral = { id: null, valor: null };
        this.form.veiculo.anoFabricacao = null;
        this.form.veiculo.anoModelo = null;
      }
      this.buscaDadosFipe('ConsultarModelos', 'modelos', 'name', 'id');
    },
    async preencheAnos() {
      if (!this.placaEncontrada) {
        this.anosModelo = [];
        this.form.veiculo.anoFabricacao = null;
        this.form.veiculo.codigoModelo = this.form.veiculo.modelo.id;
      }

      await this.buscaDadosFipe(
        'ConsultarAnoModelo',
        'anosModelo',
        'name',
        'id',
      );

      var auxAnos = [];

      this.anosModelo.forEach((element) => {
        if (element.value) {
          var ano = element.value.valor.split(' ')[0];
          var combustivel = element.value.valor.split(' ')[1];
          var codigoTipoCombustivel = element.value.id.split('-')[1];

          const _CodCombustivel =
            codigoTipoCombustivel == 1
              ? 'Gasolina'
              : codigoTipoCombustivel == 2
              ? 'Alcool'
              : 'Diesel';

          let textAnoGeral = null;
          if (ano == 32000) {
            textAnoGeral = `0km ${combustivel || _CodCombustivel}`;
          }

          auxAnos.push({
            value: {
              ano: ano,
              combustivel: combustivel,
              codigoTipoCombustivel: codigoTipoCombustivel,
            },
            text: textAnoGeral || element.value.valor,
          });
        }
      });

      this.anosModelo = auxAnos;

      if (this.idModelo != null) {
        this.anosFabricacao = [
          {
            value: this.form.veiculo.anoModelo,
            text:
              this.form.veiculo.anoModelo == '32000'
                ? '0km'
                : this.form.veiculo.anoModelo,
          },
          {
            value:
              this.form.veiculo.anoModelo == '32000'
                ? null
                : this.form.veiculo.anoModelo - 1,
            text:
              this.form.veiculo.anoModelo == '32000'
                ? null
                : this.form.veiculo.anoModelo - 1,
          },
        ];
      } else {
        this.idAnoModelo = null;
      }

      this.anosModelo.unshift({ text: 'Selecione', value: null });
    },
    async selecionaAnoModelo() {
      this.form.veiculo.ano = parseInt(this.form.veiculo.anoGeral.ano);
      this.form.veiculo.codigoTipoCombustivel =
        this.form.veiculo.anoGeral.codigoTipoCombustivel;
      this.form.veiculo.anoModelo = parseInt(this.form.veiculo.anoGeral.ano);
      this.form.veiculo.combustivel = this.form.veiculo.anoGeral.combustivel;
      this.form.veiculo.anoFabricacao = null;

      this.anosFabricacao = [
        this.form.veiculo.anoModelo != '32000'
          ? this.form.veiculo.anoModelo
          : {
              value: 32000,
              text: '0km',
            },
        this.form.veiculo.anoModelo != '32000'
          ? this.form.veiculo.anoModelo - 1
          : '',
      ];

      // trata itens falsy
      this.anosFabricacao = this.anosFabricacao.filter((ano) => ano);

      this.buscaDadosFipe(
        'ConsultarValorComTodosParametros',
        'form.veiculo.resultFipe',
        '',
        '',
      );

      if (this.alterarGrupoVeiculoCotacao && this.placaEncontrada) {
        const idMarca = this.form.veiculo.codigoMarca;
        const idModelo = this.form.veiculo.codigoModelo;
        const anoFabricacao = form.veiculo.anoFabricacao;
        const anoModelo = form.veiculo.anoModelo;
        const tipo = this.form.veiculo.tipoVeiculo;
        const _idEmpresa = this.form.idEmpresa;
        const objBuscaGrupo = {
          idEmpresa: _idEmpresa,
          idMarca,
          idModelo,
          tipo,
          anoFabricacao,
          anoModelo,
          importado: this.verificaEmpresa()
            ? this.verificaVeiculoImportado()
            : null,
        };
        this.tiposFipe = this.tiposFipeAux;

        // busca os grupos
        const grupoMarcaModelo = await service.post(
          'CotacaoAberta',
          'buscaGrupoMarcaModelo',
          objBuscaGrupo,
        );

        this.preencheGruposPosSelecao(grupoMarcaModelo.data, tipo);
        /*item.selecionado = true;*/
        this.buscaDadosFipe(
          'ConsultarModelosPorAno',
          'modelosPorAno',
          'name',
          'id',
        );
      }

      this.anosFabricacao.unshift({ text: 'Selecione', value: null });
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf' || model == 'domTipoFipe') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this.grupos.push(element);
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        this.carregando = false;
      });
    },
    buscaDadosFipe(action, array, campoDescricao, campoPK) {
      return new Promise(async (resolve, reject) => {
        this.carregando = true;

        const _codigoTipoVeiculo = this.form.veiculo.tipoVeiculo
          ? this.form.veiculo.tipoVeiculo
          : this.form.idTipoFipe
          ? this.form.idTipoFipe.id
          : null;

        var formFipe = {
          codigoTabelaReferencia: this.codigoTabelaReferencia,
          codigoTipoVeiculo: _codigoTipoVeiculo,
          codigoMarca: this.form.veiculo.codigoMarca,
          ano:
            this.form.veiculo.anoModelo +
            '-' +
            this.form.veiculo.codigoTipoCombustivel,
          codigoTipoCombustivel: this.form.veiculo.codigoTipoCombustivel,
          anoModelo: this.form.veiculo.anoModelo,
          codigoModelo: this.form.veiculo.codigoModelo,
        };
        service
          .post('Fipe', `${action}`, formFipe)
          .then((res) => {
            var arrayRes = res.data;
            if (array == 'form.veiculo.resultFipe') {
              this.form.veiculo.resultFipe = arrayRes;
              this.carregando = false;
              this.atualizaDadosValores();
              resolve();
              return;
            }
            if (array == 'modelos') {
              this.modelos = [];
              arrayRes[0].forEach((element) => {
                this.modelos.push({
                  value: { id: element['id'], valor: element['name'] },
                  text: element['name'],
                });
              });
              this.anosModelo = [];
              arrayRes[1].forEach((element) => {
                this.anosModelo.push({
                  value: {
                    ano: element.name.split(' ')[0],
                    combustivel: element.name.split(' ')[1],
                    codigoTipoCombustivel: element.id.split('-')[1],
                  },
                  text: element.name,
                });
              });
            } else if (array == 'modelosPorAno') {
              this.modelos = [];
              arrayRes.forEach((element) => {
                this.modelos.push({
                  value: { id: element['id'], valor: element['name'] },
                  text: element['name'],
                });
              });
            } else {
              arrayRes.forEach((element) => {
                if (array == 'codigoTabelaReferencia') {
                  this[array] = element[campoPK];
                } else
                  this[array].push({
                    value: {
                      id: element[campoPK],
                      valor: element[campoDescricao],
                    },
                    text: element[campoDescricao],
                  });
              });
            }

            this.carregando = false;
            resolve();
          })
          .catch(() => {
            this.carregando = false;
            this.$bvToast.toast('Ocorreu um erro, por favor tente novamente.', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscaRegionais(model, array, campoDescricao, campoPK, cidade, estado) {
      this.regionais = [{ text: null, label: 'Selecione' }];
      let objSend = {};
      objSend.idEmpresa = this.form.idEmpresa;
      service
        .post('Usuario', 'find/' + this.id_usuario, objSend)
        .then((res) => {
          res.data.forEach((element) => {
            if (element.Usuarios_Regional) {
              this.form.regional = element.Usuarios_Regional.idRegional;
              this.regionais.push({
                id: element.Usuarios_Regional.idRegional,
                label: element.Usuarios_Regional.nome,
              });
            }

            if (element.usuarios_regionais.length > 0) {
              this.usuarios_regionais = element.usuarios_regionais;
              this.usuarios_regionais.forEach((elementSel) => {
                var aux = this.regionais.find(
                  (x) => x.id == elementSel.idRegional,
                );
                if (!aux)
                  this.regionais.push({
                    id: elementSel.idRegional,
                    label: elementSel.nome,
                  });
              });
            }
            this.form.idDivisao = element.idDivisao;
            this.buscaDivisoes();
          });
        });
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit() {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          this.popToast();
          return;
        }

        if (this.passo == 1) {
          if (!this.placaValida) {
            this.$bvToast.toast(
              'Já existe uma cotação aberta para esta placa',
              {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 3000,
              },
            );

            this.passo = 1;
            return;
          }

          if (!this.form.tipoProposta) {
            this.$bvToast.toast('Escolha o tipo da sua proposta!!!', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3000,
            });

            this.passo = 1;
            return;
          }
        }

        //verifica o tipo de pessoa
        if (this.passo == 2) {
          await this.validaPlaca();

          if (!this.placaValida) {
            this.passo = 1;
            this.btnDisabled = true;
            return;
          }

          if (this.form.celular) {
            if (!this.verificaCelular(this.form.celular)) {
              return;
            }
          }
          if (!this.form.tipoPessoa) {
            this.$bvToast.toast('Selecione o Tipo de Pessoa', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
            return;
          }

          if (this.verificaEmpresa()) {
            this.passo = 4;
            await this.buscaDadosPlaca();

            return;
          }

          // return;
        }

        // verifica as restrições e BuscaDadosPlaca
        if (this.passo == 3) {
          var restricaoAbsoluta = false;

          if (this.form.restricoesSelecionadas.length == 0) {
            this.$bvToast.toast('Selecione a opção Nenhuma Restrição', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
            return;
          }

          if (this.form.restricoesSelecionadas.length > 0) {
            if (
              this.form.restricoesSelecionadas.length > 1 &&
              this.form.restricoesSelecionadas.indexOf(null) >= 0
            ) {
              this.$bvToast.toast('Desmarque a opção Nenhuma Restrição', {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 2500,
              });
              return;
            }
            this.form.restricoesSelecionadas.forEach((element) => {
              var restricao = this.restricoes.find(
                (x) => x.idRestricao == element,
              );
              if (restricao.restricaoAbsoluta == true) restricaoAbsoluta = true;
            });
          }
          if (restricaoAbsoluta == true) {
            //Insere Proposta Negativa
            this.carregando = true;
            this.form.statusProposta = false;
            this.form.msgNegativo = 'Veículo com Restrição Absoluta';
            await service
              .post('Cotacao', 'InserirProposta', this.form)
              .then((res) => {
                this.passo = 6;
                this.resultNegativo = true;
                this.msgNegativo = this.form.msgNegativo;
                this.carregando = false;
              });
            return;
          }

          await this.buscaDadosPlaca();
        }

        if (this.passo == 4) {
          const _placaValida = await this.validarPlacaAposPrimeiraEtapa();
          if (!_placaValida) {
            return;
          }

          if (this.fipeEncontrada == false) {
            this.buscaDadosFipe(
              'ConsultarValorComTodosParametros',
              'form.veiculo.resultFipe',
              '',
              '',
            ).then(() => {
              this.carregando = true;

              this.buscaProdutos()
                .then(() => {
                  this.buscaFormasPagamentoPadroes();
                })
                .catch((e) => {
                  this.form.statusProposta = false;
                  this.form.msgNegativo = e;
                  service
                    .post('Cotacao', 'InserirProposta', this.form)
                    .then((res) => {
                      this.passo = 6;
                      this.resultNegativo = true;
                      this.msgNegativo = e;
                      this.carregando = false;
                    });
                });
            });
          } else {
            let preco = '0';

            const temdadosFipe = !utils.isObjectEmpty(
              this.form.veiculo.resultFipe,
            );

            if (temdadosFipe) {
              preco = this.form.veiculo.resultFipe.preco
                .slice(3)
                .replace(',', '.');
            }

            preco = parseFloat(preco);
            const temAnoFabricao = this.form.veiculo.anoFabricacao;

            if (
              (preco == 0 && !this.form.somenteAgregado) ||
              this.form.valorCobertura === 0 ||
              !temAnoFabricao
            ) {
              let mensagem = 'Valor FIPE ou Valor Cobertura zerados';

              if (!temAnoFabricao) {
                this.resetBuscaPlaca();
                mensagem =
                  'Não foi possível encontrar os dados FIPE preencha manualmente';
              }

              this.passo = 4;

              this.$bvToast.toast(mensagem, {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 5000,
              });

              return;
            } else {
              if (!this.form.idTipoFipe.id) {
                this.$bvToast.toast('Selecione o Tipo de Veiculo', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                return;
              }

              if (
                this.form.implemento == true &&
                this.form.implementos.length == 0
              ) {
                this.$bvToast.toast('Nenhum Implemento foi Adicionado', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                return;
              }

              if (
                this.form.agregado == true &&
                this.form.agregados.length == 0
              ) {
                this.$bvToast.toast('Nenhum Agregado foi Adicionado', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                return;
              }

              if (!this.form.idGrupo.id) {
                this.$bvToast.toast('Selecione o Grupo', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                return;
              }
              this.carregando = true;
              this.buscaProdutos()
                .then(() => {
                  this.buscaFormasPagamentoPadroes();
                })
                .catch((e) => {
                  this.form.statusProposta = false;
                  this.form.msgNegativo = e;
                  service
                    .post('Cotacao', 'InserirProposta', this.form)
                    .then((res) => {
                      this.passo = 6;
                      this.resultNegativo = true;
                      this.msgNegativo = e;
                      this.carregando = false;
                    });
                });
            }
          }
        }

        if (this.passo == 5) {
          const _placaValida = await this.validarPlacaAposPrimeiraEtapa();

          if (!_placaValida) return;

          this.btnDisabled = true;
          this.carregando = true;

          this.form.statusProposta = true;
          this.form.produtosSelecionadosAux = [];
          this.form.jsonFacilitadorProdutos = JSON.stringify(this.produtos);

          if (this.verificaEmpresa() && this.form.isZero) {
            this.form.veiculo.anoFabricacao = this.ajustaAnoFabricaoZeroKm();
          }

          this.form.produtosSelecionados.forEach((element) => {
            const aux = this.produtos.find((x) => x.id_produto == element.id);
            const desconto = aux.descontoConcedido ? aux.descontoConcedido : 0;

            let id_tipoPagamentoEmpresa = null;
            const bemProtege = this.verificaEmpresa();

            if (bemProtege) {
              id_tipoPagamentoEmpresa = aux.ismensal
                ? this.formaPagamentoPadraoMensal.id_tipoPagamentoEmpresa
                : this.formaPagamentoPadraoEntrada.id_tipoPagamentoEmpresa;
            }

            this.form.produtosSelecionadosAux.push({
              id: aux.id_produto,
              valor: aux.valor,
              valorFinal: aux.valorFinal,
              valorOriginal: aux.valorOriginal,
              descontoConcedido: desconto,
              idTipoDesconto: aux.idTipoDesconto,
              ismensal: aux.ismensal,
              id_tipoPagamentoEmpresa,
            });
          });

          if (this.form.produtosSelecionadosAux.length > 0)
            service
              .post('Cotacao', 'InserirProposta', this.form)
              .then((res) => {
                this.idPreCotacao && this.convertePreCotacao(res.data);
                this.carregando = false;
              })
              .catch((e) => {
                this.passo = 6;
                this.resultNegativo = true;
                this.msgNegativo = e;
                this.carregando = false;

                this.$bvToast.toast(e, {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 5000,
                });
              });
        }

        if (this.passo == 6) {
          this.$router.push('/dashboard');
          return;
        }

        this.passo = this.passo + 1;
      });
    },
    convertePreCotacao(data) {
      service
        .put('PreCotacao', 'ConverterPreCotacao', {
          idEmpresa: data.idEmpresa,
          idPropostaConvertida: data.idProposta,
          idPreCotacao: this.idPreCotacao,
        })
        .then((res) => {
          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          this.passo = 6;
          this.resultNegativo = true;
          this.msgNegativo = e;

          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 5000,
          });
        });
    },
    resetBuscaPlaca() {
      this.form.resultadoPlaca = null;
      this.form.chassi = null;
      this.form.renavam = null;
      this.form.combustivel = null;
      this.form.cor = null;
      this.form.quantidadePassageiro = null;
      this.form.idGrupo = { id: null, valor: null };
      this.placaEncontrada = false;
      this.form.veiculo.anoFabricacao = null;
      this.form.veiculo.anoGeral = null;
      this.form.veiculo.combustivel = null;
      this.form.veiculo.numeroMotor = null;
      this.form.veiculo.resultFipe = null;
      this.form.veiculo.tipoVeiculo = null;
      this.form.veiculo.tipoCambio = null;
      this.form.veiculo.anoModelo = null;
      this.form.valorCobertura = 0;
      this.form.valorTotalConjunto = 0;
      this.form.precoVeiculo = 0;
      this.form.somenteAgregado = false;

      this.form.idTipoFipe = null;
      this.tiposFipe = [];
      this.tiposFipe = this.tiposFipeAux;
      this.tiposVeiculos = [];
      this.propostaParaSomenteAgregado = false;
      this.mostrarModalGrupos = false;

      this.btnDisabled = false;
    },
    preencheDadosPlaca() {
      return new Promise(async (resolve, reject) => {
        if (this.dadosPlaca) {
          this.tiposFipe = this.tiposFipeAux;

          if (
            this.dadosPlaca.mensagemRetorno.indexOf(
              'Nenhum veículo foi encontrado',
            ) >= 0
          ) {
            this.resetBuscaPlaca();
            reject();
          } else {
            if (this.dadosPlaca.extra) {
              this.placaEncontrada = true;
              this.form.resultadoPlaca = this.dadosPlaca;
              this.form.chassi = this.dadosPlaca.extra.chassi.toUpperCase();
              this.form.renavam = this.dadosPlaca.extra.renavam;
              this.form.combustivel = this.dadosPlaca.extra.combustivel;
              this.form.cor = this.dadosPlaca.cor;
              this.form.veiculo.numeroMotor = this.dadosPlaca.extra.motor;

              this.form.quantidadePassageiro =
                this.dadosPlaca.extra.quantidade_passageiro;

              if (this.dadosPlaca.extra.tipo_veiculo) {
                var idTipoFipe = this.pegarTipoVeiculo(
                  this.dadosPlaca.extra.tipo_veiculo,
                );
                const aux = this.tiposFipe.find(
                  (x) => x.value && x.value.id == idTipoFipe,
                );
                this.form.idTipoFipe = aux.value;

                if (idTipoFipe == 2)
                  this.form.veiculo.cilindradas =
                    this.dadosPlaca.extra.cilindradas;

                this.selecionaTipoFipe();
              }
            }

            if (this.dadosPlaca.fipe.dados) {
              this.btnDisabled = true;
              this.placaEncontrada = true;
              this.modelosFipeEncontrados = [];
              this.dadosPlaca.fipe.dados.forEach((element) => {
                element.selecionado = false;
                element.checked = null;
                this.modelosFipeEncontrados.push(element);
              });
              this.modelosFipeEncontrados.sort(function (a, b) {
                return a.score > b.score ? -1 : a.score < b.score ? 1 : 0;
              });
            }
          }
          resolve();
        }
      });
    },
    pegarTipoVeiculo(tipo_veiculo) {
      const tipoVeiculo = this.dicionarioTiposVeiculos[tipo_veiculo];
      return tipoVeiculo ? tipoVeiculo.codigoTipo : 3;
    },
    async validarPlacaAposPrimeiraEtapa() {
      const _placaValida = await this.validaPlaca();

      if (!_placaValida) {
        this.passo = 4;
        this.mostrarModalGrupos = false;
        this.carregando = false;
        this.mostrarModalGrupos = false;
        this.btnDisabled = true;
        this.modelosFipeEncontrados.forEach(
          (item) => (item.selecionado = false),
        );

        this.buscarPlacaNovamente = true;
      }

      return _placaValida;
    },
    async selecionaModelo(item) {
      const _placaValida = await this.validarPlacaAposPrimeiraEtapa();

      if (!_placaValida) return;

      let grupoMarcaModelo = null;
      let tipo = null;

      this.carregando = true;

      for (let index = 0; index < this.modelosFipeEncontrados.length; index++) {
        const element = this.modelosFipeEncontrados[index];
        element.selecionado = false;
      }
      item.selecionado = true;

      if (!this.form.idTipoFipe) {
        var aux = this.tiposFipe.find(
          (x) => x.value && x.value.id == item.tipo_modelo,
        );
        this.form.idTipoFipe = aux.value;
        await this.selecionaTipoFipe();
      }

      //Buscar Grupo Marca/Modelo
      if (this.verificaEmpresa()) {
        if (this.alterarGrupoVeiculoCotacao) {
          const idMarca = item.codigo_marca;
          const idModelo = item.codigo_modelo;
          tipo = item.tipo_modelo;
          const anoFabricacao = item.ano_modelo;
          const anoModelo = item.ano_modelo;
          const _idEmpresa = this.form.idEmpresa;
          const objBuscaGrupo = {
            idEmpresa: _idEmpresa,
            idMarca,
            idModelo,
            tipo,
            anoFabricacao,
            anoModelo,
            importado: this.verificaEmpresa()
              ? this.verificaVeiculoImportado()
              : null,
          };
          this.tiposFipe = this.tiposFipeAux;
          // busca os grupos

          grupoMarcaModelo = await service.post(
            'CotacaoAberta',
            'buscaGrupoMarcaModelo',
            objBuscaGrupo,
          );

          if (grupoMarcaModelo.data.length > 0) {
            this.preencheGruposPosSelecao(grupoMarcaModelo.data, tipo);
          }
        }
      } else {
        service
          .getAll('Grupo', {}, `list/${this.form.idEmpresa}`)
          .then((res) => {
            this.preencheGruposPosSelecao(res.data, tipo);
            // this.habilitaAlterarGrupo = false;
            this.mostrarModalGrupos = true;
            this.selecaoDeGruposAtiva = true;
          });
      }

      this.carregando = false;
      this.fipeEncontrada = true;
      this.confirmaModeloFipe(item);
    },
    preencheGruposPosSelecao(grupoMarcaModelo, tipo) {
      const _grupos = [];

      if (this.verificaEmpresa()) {
        grupoMarcaModelo.forEach((item) => {
          const { id } = item;
          const grupo = this.grupos.find((item) => item.idGrupo === id);

          if (!grupo) {
            const grupoForaDaBuscaInicial = {
              idGrupo: item.id,
              nomeGrupo: item.label,
              descricao: item.label,
              idTipoFipe: item.idTipoFipe,
            };
            _grupos.push(grupoForaDaBuscaInicial);
            this.grupoForaDaBuscaInicial = grupoForaDaBuscaInicial;
          } else {
            _grupos.push(grupo);
          }
        });
      } else {
        grupoMarcaModelo.forEach((item) => {
          const grupoOutraEmpresa = {
            idGrupo: item.id ? item.id : item.idGrupo,
            nomeGrupo: item.label ? item.label : item.nomeGrupo,
            descricao: item.descricao ? item.descricao : item.nomeGrupo,
            idTipoFipe: item.idTipoFipe,
          };

          if (this.form.idTipoFipe.id == item.idTipoFipe && !item.deletedAt)
            _grupos.push(grupoOutraEmpresa);
        });
      }

      // carrega o tipo de veículo

      const _tiposFipe = this.tiposFipe.reduce((acc, item) => {
        const { value } = item;

        if (value) {
          if (value.id === tipo) acc.push(item);
        }

        return acc;
      }, []);
      // carrega os grupos de veículos

      let _tiposVeiculos = this.carregaGruposVeiculos(_grupos);

      if (_tiposVeiculos.length > 0) {
        if (_tiposFipe.length > 0) {
          this.tiposFipe = _tiposFipe;
        }

        if (this.tiposFipe[0].value) {
          this.form.idTipoFipe = {
            id: this.tiposFipe[0].value.id,
            valor: this.tiposFipe[0].text,
          };
        }

        if (_tiposVeiculos.length > 0) {
          this.tiposVeiculos = _tiposVeiculos;
        }

        if (this.tiposVeiculos[0].value) {
          if (this.form.idGrupo && this.form.idGrupo.id) {
            let grupoExistente = grupoMarcaModelo.find(
              (x) => x.id == this.form.idGrupo.id,
            );
            if (!grupoExistente)
              this.form.idGrupo = {
                id: this.tiposVeiculos[0].value.id,
                valor: this.tiposVeiculos[0].text,
              };
          } else
            this.form.idGrupo = {
              id: this.tiposVeiculos[0].value.id,
              valor: this.tiposVeiculos[0].text,
            };
        }

        this.habilitaAlterarGrupo = true;
        this.selecionaTipo();
      }

      // seleciona novamente o grupo de veículo
      //

      if (_tiposVeiculos.length > 1) {
        this.mostrarModalGrupos = true;
        this.habilitaAlterarGrupo = false;
        this.$bvToast.toast(
          'Mais de um grupo encontrado, selecione um Grupo de Veículo',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'warning',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 5000,
          },
        );
      }

      if (_tiposVeiculos.length === 0) {
        const gruposPorTipo = this.grupos.filter(
          (grupo) => grupo.idTipoFipe === this.form.idTipoFipe.id,
        );
        this.tiposVeiculos = this.carregaGruposVeiculos(gruposPorTipo);
        this.mostrarModalGrupos = true;
        this.habilitaAlterarGrupo = false;
        this.carregando = false;

        this.$bvToast.toast(
          'Nenhum grupo encontrado, selecione um Grupo de Veículo',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 5000,
          },
        );
      }
    },
    carregaGruposVeiculos(grupos) {
      return grupos.reduce((acc, item) => {
        const { idGrupo: id, nomeGrupo: valor, descricao } = item;
        acc.push({
          value: { id, valor },
          text: valor,
          descricao,
        });
        return acc;
      }, []);
    },
    confirmaModeloFipe(modelo) {
      if (!this.form.idTipoFipe.id) {
        this.$bvToast.toast('Selecione o Tipo e o Grupo', {
          title: 'Atenção!',
          solid: true,
          variant: 'danger',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
      }

      if (!this.form.idTipoFipe) {
        var aux = this.tiposFipe.find(
          (x) => x.value && x.value.id == modelo.tipo_modelo,
        );

        this.form.idTipoFipe = aux.value;
        this.selecionaTipoFipe();
      }

      const codigoTipoVeiculo =
        typeof this.form.idTipoFipe === 'number'
          ? this.form.idTipoFipe
          : this.form.idTipoFipe.id;

      var objSend = { ...modelo };

      objSend.codigoTipoVeiculo = codigoTipoVeiculo;
      objSend.anoModelo = this.dadosPlaca.anoModelo;
      //

      this.buscaDadosFipePorModelo(objSend);
      /*setTimeout(() => {
        modelo.selecionado = true;
      }, 10);*/
    },
    async buscaDadosFipePorModelo(objSend) {
      this.carregando = true;
      let res = await service.post('Cotacao', 'BuscaDadosFipeModelo', objSend);

      this.carregando = false;
      if (res.data.preco) {
        this.form.veiculo.resultFipe = res.data;
        this.form.veiculo.anoModelo = this.dadosPlaca.anoModelo
          ? parseInt(this.dadosPlaca.anoModelo)
          : null;

        this.form.veiculo.anoFabricacao = this.dadosPlaca.ano
          ? parseInt(this.dadosPlaca.ano) ||
            parseInt(this.dadosPlaca.extra.ano_fabricacao)
          : res.data.ano;

        this.form.veiculo.combustivel = res.data.combustivel;
        this.btnDisabled = false;
        this.atualizaDadosValores();
      } else {
        this.resetBuscaPlaca();
        this.carregando = false;
        const mensagem =
          'Os dados FIPE não foram encontrados para essa placa,preencha manualmente';

        utils.showToast(this.$bvToast, mensagem, 'warning');
        this.scrollToElement();

        this.iptTipoVeiculo.focus();
        this.$refs.iptTipoVeiculo.$el.focus();
        this.$refs.iptTipoVeiculo.focus();
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = '';
      this.form.name = '';
      this.form.food = null;
      this.form.isZero = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.permiteAlterarRegional = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    keymonitor: function (event) {
      /*if (this.form.cep.length == 10) {
        this.buscarCep();
      }*/
    },
    keymonitorPlaca: async function (event) {
      if (this.form.placa.length == 8) {
        await this.validaPlaca();

        if (this.passo == 4 && this.placaValida && this.buscarPlacaNovamente) {
          this.buscarPlacaNovamente = false;

          await this.buscaDadosPlaca();
        }
      }
    },
    buscaRestricoes() {
      return new Promise(async (resolve, reject) => {
        this.carregando = true;

        service
          .getAll('Restricoes', {}, `list/${this.form.idEmpresa}`)
          .then((res) => {
            var aux = res.data.filter((x) => x.deletedAt == null);
            this.restricoes = aux;
            this.restricoes.push({
              idRestricao: null,
              descricao: 'Nenhuma Restrição',
            });
          });
      });
    },
    buscaProdutos() {
      return new Promise(async (resolve, reject) => {
        this.carregando = true;

        service
          .post('Cotacao', 'BuscaProdutos', this.form)
          .then((res) => {
            this.carregando = false;
            if (res.data.length == 0) {
              this.$bvToast.toast(
                'Nenhum Produto Encontrado para esse veículo',
                {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                },
              );
              reject('Nenhum Produto Encontrado para esse veículo');
            } else {
              var prodsOk = true;

              if (this.form.idTipoFipe.id != 3 || this.form.somenteAgregado)
                this.produtos = res.data;

              if (this.form.idTipoFipe.id == 3 && !this.form.somenteAgregado) {
                this.produtos = res.data.produtosVeiculo;

                if (res.data.produtosAgregados.length > 0) {
                  let _agregados = res.data.produtosAgregados.reduce(
                    (acc, item) => {
                      let _produtosObrigatorios = [];
                      let _produtosNaoObrigatorios = [];
                      let _produtosSelecionados = [];

                      item.produtos.forEach((produto) => {
                        produto.valorFinal = produto.valor;
                        produto.descontoConcedido = 0;
                        produto.id = produto.id_produto;

                        if (produto.obrigatorio === true) {
                          _produtosObrigatorios.push(produto);
                          _produtosSelecionados.push({ id: produto.id });
                        } else {
                          _produtosNaoObrigatorios.push(produto);
                        }
                      });

                      const agregado = {
                        ...item,
                        produtosObrigatorios: _produtosObrigatorios,
                        produtosNaoObrigatorios: _produtosNaoObrigatorios,
                        produtosSelecionados: _produtosSelecionados,
                      };

                      acc.push(agregado);

                      return acc;
                    },
                    [],
                  );

                  this.form.agregados = _agregados;
                }
              }

              this.produtosObrigatorios = [];
              this.produtosNaoObrigatorios = [];
              this.form.produtosSelecionados = [];

              this.produtos.forEach((element) => {
                element.valorFinal = element.valor;
                element.descontoConcedido = 0;
                if (element.obrigatorio == true) {
                  this.produtosObrigatorios.push(element);
                  this.form.produtosSelecionados.push({
                    id: element.id_produto,
                  });
                } else {
                  this.produtosNaoObrigatorios.push(element);
                }

                if (element.valor == null) {
                  prodsOk = false;
                }
                if (element.cota != null) this.form.cotaTabela = element.cota;
                if (element.id_tabelaValor != null)
                  this.form.idTabelaValor = element.id_tabelaValor;
              });
              if (prodsOk == false) {
                this.$bvToast.toast('Valores fora de Tabela', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                reject('Valores fora de Tabela');
              } else {
                resolve();
              }
            }
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
            reject(e);
          });
      });
    },
    somaValorTotalMes(arrSelecionados, arrProds) {
      var total = 0;
      arrSelecionados.forEach((element) => {
        var prod = arrProds.find((x) => x.id_produto == element.id);
        if (prod) if (prod.ismensal == true) total = total + prod.valorFinal;
      });
      return total;
    },
    somaValorTotal(arrSelecionados, arrProds) {
      var total = 0;
      arrSelecionados.forEach((element) => {
        var prod = arrProds.find((x) => x.id_produto == element.id);
        if (prod) if (prod.ismensal != true) total = total + prod.valorFinal;
      });
      return total;
    },
    async validaPlaca() {
      if (this.isCarroZero) {
        this.btnDisabled = false;
        return this.placaValida;
      }

      if (this.form.placa.length == 8) {
        this.placaValida = true;
        this.carregando = true;
        this.btnDisabled = true;

        const dadosConsultaPlaca = await consultaPlaca({
          placa: this.form.placa,
          idEmpresa: this.form.idEmpresa,
          somenteAgregado: this.propostaParaSomenteAgregado,
          permitePlacaRepetida: this.permitePlacaRepetida,
        });
        this.codigoSituacaoPlaca = dadosConsultaPlaca.codigoSituacao;

        this.$refs.btnAvancar.focus();
        this.carregando = false;

        if (dadosConsultaPlaca.codigoSituacao == '999') {
          this.placaValida = false;

          this.$refs.btnAvancar.focus();
          this.carregando = false;

          const _messagemPlacaRecusada = `Regional ${dadosConsultaPlaca.regionalConsultorPlaca}, Consultor ${dadosConsultaPlaca.nomeConsultorPlaca}.`;

          this.situacaoPlacaModaTitle = 'Placa recusada!';

          this.messagemPlacaRecusada = _messagemPlacaRecusada;

          this.modalPlacaRecusada = dadosConsultaPlaca.modalPlacaRecusada;
          this.regionalConsultorPlaca =
            dadosConsultaPlaca.regionalConsultorPlaca;
          this.nomeConsultorPlaca = dadosConsultaPlaca.nomeConsultorPlaca;

          this.btnDisabled = true;
        }

        if (this.passo == 1) {
          this.form.chassi = null;
        }
        await this.validaPlacaBaseBP('placa');

        if (this.codigoSituacaoPlaca === '0' || dadosConsultaPlaca === true) {
          this.btnDisabled = false;
        }

        return this.placaValida;
      }
    },
    async validaChassiBaseBP() {
      if (this.form.chassi.length === 17) {
        await this.validaPlacaBaseBP(null, 'chassi');
      }
    },
    async validaPlacaBaseBP(placa = null, chassi = null) {
      const isBP = utils.verificaEmpresaBemProtege(this.form.idEmpresa);
      if (!isBP || (this.form.placa && this.codigoSituacaoPlaca === '999')) {
        return true;
      }

      const dadosConsultaPlaca = await consultarPlacaChassiBaseBP({
        placa: this.form.placa,
        idEmpresa: this.form.idEmpresa,
        chassi: this.form.chassi,
      });

      if (dadosConsultaPlaca.codigoSituacao == '998') {
        this.form.tipoProposta = null;
        this.placaValida = true;
        this.$refs.btnAvancar.focus();
        this.carregando = false;
        this.situacaoPlacaModaTitle = 'Placa/Chassi encontradas!';
        this.messagemPlacaRecusada = dadosConsultaPlaca.situacao;
        this.modalPlacaRecusada = dadosConsultaPlaca.modalPlacaRecusada;
        this.btnDisabled = false;

        this.regionalConsultorPlaca = null;
        this.nomeConsultorPlaca = null;
      } else {
        this.carregando = false;
        this.btnDisabled = false;
      }
      this.codigoSituacaoPlaca = dadosConsultaPlaca.codigoSituacao;

      return dadosConsultaPlaca;
    },
    buscarCep() {
      if (this.cep.length == 10) {
        this.form.cep = this.cep;
        this.carregando = true;
        var cepbusca = this.form.cep.replace('.', '').replace('-', '');
        //
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;
            const parsed = JSON.stringify(res.data);
            localStorage.setItem('cepCarrinho', parsed);
            this.form.endereco = res.data.street;
            this.form.bairro = res.data.neighborhood;
            this.form.cidade = res.data.city;
            this.form.estado = res.data.state;
            this.form.pais = 'BR';
            this.$refs.btnAvancar.focus();
            this.carregando = false;
          })
          .catch((e) => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.form.endereco = null;
            this.form.bairro = null;
            this.form.cidade = null;
            this.form.estado = null;
            this.form.pais = null;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    buscaDadosToken() {
      this.temaCor = colors.getColorsTema();
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);

        if (this.decoded.tipo == 'Consultor') {
          this.form.idUsuario = this.decoded.id_usuario;
          this.form.idEmpresa = this.decoded.id_empresa;
          this.idPerfil = this.decoded.id_perfil;
        } else {
          this.$router.push('/dashboard');
        }
        this.id_usuario = this.decoded.id_usuario;
      }
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.form.estado.id}`)
        .then((res) => {
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
    async selecionaTipoFipe() {
      try {
        let tipoVeiculoNaoPermitidoParaAgregado = null;
        if (this.idTipoFipeAux)
          tipoVeiculoNaoPermitidoParaAgregado =
            this.form.idTipoFipe.id != this.idTipoFipeAux.id &&
            this.propostaParaSomenteAgregado;

        if (tipoVeiculoNaoPermitidoParaAgregado) {
          this.propostaParaSomenteAgregado = false;
          this.form.somenteAgregado = false;
          this.resetBuscaPlaca();
        }

        if (!this.placaEncontrada) {
          this.form.idGrupo = { id: null, valor: null };
          this.form.veiculo.marca = { id: null, valor: null };
          this.form.veiculo.modelo = { id: null, valor: null };
          this.tiposVeiculos = [];
          this.marcas = [];
          this.modelos = [];
          this.anosModelo = [];
          this.anosFabricacao = [];
          this.form.veiculo.anoGeral = { id: null, valor: null };
          this.form.veiculo.anoFabricacao = null;
          this.form.veiculo.anoModelo = null;
        }

        if (this.form.idTipoFipe) {
          this.carregando = true;

          var url = `gruposVeiculos/list/idEmpresa/${this.form.idEmpresa}`;
          this.grupos = [];
          this.tiposVeiculos = [];

          const res = await service.getAll('g', {}, url);

          const grupos =
            this.verificaEmpresa() && this.form.chassi
              ? this.separaGruposImportados(res.data, this.form.idTipoFipe)
              : res.data;

          grupos.forEach((element) => {
            this.grupos.push(element);

            let _idTipoFipe = null;
            if (this.form.idTipoFipe.hasOwnProperty('id')) {
              _idTipoFipe = this.form.idTipoFipe.id;
            } else {
              _idTipoFipe = this.form.idTipoFipe.id;
            }

            if (
              element.idTipoFipe == _idTipoFipe &&
              !this.propostaParaSomenteAgregado
            ) {
              this.tiposVeiculos.push({
                value: { id: element.idGrupo, valor: element.nomeGrupo },
                text: element.nomeGrupo,
                descricao: element.descricao,
              });
              element.selecionado = true;
            }

            if (
              this.propostaParaSomenteAgregado &&
              element.permiteSomenteAgregado
            ) {
              this.tiposVeiculos.push({
                value: { id: element.idGrupo, valor: element.nomeGrupo },
                text: element.nomeGrupo,
                descricao: element.descricao,
              });
              element.selecionado = true;
            }
          });

          this.carregando = false;

          if (this.placaEncontrada) {
            this.selecaoDeGruposAtiva = true;
            if (this.grupos.length > 1) {
              this.mostrarModalGrupos = true;
            }
          }
        }
      } catch (error) {}
    },
    buscaProdutosVinculo() {
      this.form.produtosSelecionados.forEach((element) => {
        var aux = this.produtos.find((x) => x.id_produto == element.id);
        if (aux)
          if (aux.produtosLinkados) {
            var prodsLink = aux.produtosLinkados.split(',');

            prodsLink.forEach((element) => {
              var aux2 = this.produtos.find((x) => x.id_produto == element);
              var aux3 = this.form.produtosSelecionados.find(
                (x) => x.id == element,
              );

              if (aux2 && !aux3) {
                this.form.produtosSelecionados.push({
                  id: aux2.id_produto,
                });
              }
            });
          }
      });
    },
    buscaProdutosSobrepoeFaltante() {
      var arrFaltante = [];
      this.produtosObrigatorios.forEach((element) => {
        var aux = this.form.produtosSelecionados.find(
          (x) => x.id == element.id_produto,
        );
        if (!aux) arrFaltante.push(element);
      });
      if (arrFaltante.length > 0) {
        var arrEncontrou = [];
        arrFaltante.forEach((elementFaltante) => {
          this.form.produtosSelecionados.forEach((element) => {
            var aux = this.produtos.find((x) => x.id_produto == element.id);
            if (aux.sobreporOutrosProdutos) {
              var prodssobreporOutrosProdutos =
                aux.sobreporOutrosProdutos.split(',');
              var aux2 = prodssobreporOutrosProdutos.filter(
                (x) => x == elementFaltante.id_produto,
              );
              if (aux2.length > 0) {
                arrEncontrou.push(aux2[0]);
              }
            }
          });

          if (arrEncontrou.length == 0) {
            this.form.produtosSelecionados.push({
              id: elementFaltante.id_produto,
            });
          }
        });
      }
    },
    buscaProdutosSobrepoeFaltanteAgregados(item, id) {
      var arrFaltante = [];
      this.form.agregados[id].produtosObrigatorios.forEach((element) => {
        var aux = this.form.agregados[id].produtosSelecionados.find(
          (x) => x.id == element.id_produto,
        );
        if (!aux) arrFaltante.push(element);
      });
      if (arrFaltante.length > 0) {
        var arrEncontrou = [];
        arrFaltante.forEach((elementFaltante) => {
          this.form.agregados[id].produtosSelecionados.forEach((element) => {
            var aux = this.form.agregados[id].produtos.find(
              (x) => x.id_produto == element.id,
            );
            if (aux.sobreporOutrosProdutos) {
              var prodssobreporOutrosProdutos =
                aux.sobreporOutrosProdutos.split(',');
              var aux2 = prodssobreporOutrosProdutos.filter(
                (x) => x == elementFaltante.id_produto,
              );
              if (aux2.length > 0) {
                arrEncontrou.push(aux2[0]);
              }
            }
          });

          if (arrEncontrou.length == 0) {
            this.form.agregados[id].produtosSelecionados.push({
              id: elementFaltante.id_produto,
            });
          }
        });
      }
    },
    validaSelecionado(item) {
      var aux = this.form.produtosSelecionados.filter(
        (x) => x.id == item.id_produto,
      );
      if (item.obrigatorio && aux.length > 0) return true;
      else if (item.obrigatorio && aux.length == 0) return false;
      return false;
    },
    validaSelecionadoAgregado(item, id) {
      const aux = this.form.agregados[id].produtosSelecionados.filter(
        (x) => x.id == item.id_produto,
      );

      if (item.obrigatorio && aux.length > 0) return true;
      else if (item.obrigatorio && aux.length == 0) return false;

      return false;
    },
    selecionaProduto(item) {
      var disabled = this.validaSelecionado(item);

      var isInArr = false;
      this.form.produtosSelecionados.forEach((element, index) => {
        if (element.id == item.id_produto) {
          isInArr = true;
          if (!disabled) this.form.produtosSelecionados.splice(index, 1);
        }
      });

      if (isInArr == false) {
        var aux = this.produtos.find((x) => x.id_produto == item.id_produto);
        this.form.produtosSelecionados.push({
          id: item.id_produto,
        });
      }

      if (item.sobreporOutrosProdutos) {
        var prodssobreporOutrosProdutos =
          item.sobreporOutrosProdutos.split(',');

        prodssobreporOutrosProdutos.forEach((element) => {
          this.form.produtosSelecionados.forEach((elementSel, index) => {
            if (elementSel.id == element) {
              this.form.produtosSelecionados.splice(index, 1);
            }
          });
        });
      } else {
        this.produtos.forEach((element) => {
          if (element.sobreporOutrosProdutos) {
            var prodssobreporOutrosProdutos =
              element.sobreporOutrosProdutos.split(',');
            var aux = prodssobreporOutrosProdutos.find(
              (x) => x == item.id_produto,
            );
            if (aux) {
              this.form.produtosSelecionados.forEach((selecionado, index) => {
                if (selecionado.id == element.id_produto) {
                  this.form.produtosSelecionados.splice(index, 1);
                }
              });
            }
          }
        });
      }
      this.buscaProdutosSobrepoeFaltante();
    },
    selecionaProdutoAgregado(item, id) {
      var disabled = this.validaSelecionadoAgregado(item, id);

      var isInArr = false;
      this.form.agregados[id].produtosSelecionados.forEach((element, index) => {
        if (element.id == item.id_produto) {
          isInArr = true;
          if (!disabled)
            this.form.agregados[id].produtosSelecionados.splice(index, 1);
        }
      });

      if (isInArr == false) {
        this.form.agregados[id].produtosSelecionados.push({
          id: item.id_produto,
        });
      }

      if (item.sobreporOutrosProdutos) {
        const prodssobreporOutrosProdutos =
          item.sobreporOutrosProdutos.split(',');
        prodssobreporOutrosProdutos.forEach((element) => {
          this.form.agregados[id].produtosSelecionados.forEach(
            (elementSel, index) => {
              if (elementSel.id == element) {
                this.form.agregados[id].produtosSelecionados.splice(index, 1);
              }
            },
          );
        });
      } else {
        this.form.agregados[id].produtos.forEach((element) => {
          if (element.sobreporOutrosProdutos) {
            const prodssobreporOutrosProdutos =
              element.sobreporOutrosProdutos.split(',');
            const aux = prodssobreporOutrosProdutos.find(
              (x) => x == item.id_produto,
            );
            if (aux) {
              this.form.agregados[id].produtosSelecionados.forEach(
                (selecionado, index) => {
                  if (selecionado.id == element.id_produto) {
                    this.form.agregados[id].produtosSelecionados.splice(
                      index,
                      1,
                    );
                  }
                },
              );
            }
          }
        });
      }
      this.buscaProdutosSobrepoeFaltanteAgregados(item, id);
    },
    async buscarConfiguracaoEmpresa() {
      const dados = await service.getAll(
        'g',
        {},
        'empresas/list/idEmpresa/' + this.form.idEmpresa,
      );
      const [configuracoesEmpresa] = dados.data;

      this.alterarGrupoVeiculoCotacao =
        configuracoesEmpresa.alterarGrupoVeiculoCotacao;

      this.permitirRestricoes = configuracoesEmpresa.permitirRestricoes;
      this.permiteAlterarRegional = configuracoesEmpresa.permiteAlterarRegional;
      this.permitePlacaRepetida =
        configuracoesEmpresa.permiteVariasCotacoesPorPlaca;

      if (this.alterarGrupoVeiculoCotacao) {
        this.habilitaAlterarGrupo = true;
      }
      if (this.permiteAlterarRegional == false && this.passo == 0) {
        this.passo = 1;
      }
    },
    buscaDivisoes() {
      return new Promise((resolve, reject) => {
        if (this.form.regional) {
          var objSend = {
            idRegional: this.form.regional,
          };
          this.carregando = true;
          service.post('Divisao', 'BuscaDivisao', objSend).then((res) => {
            this.divisoes = [{ text: null, label: 'Selecione' }];
            res.data.forEach((element) => {
              this.divisoes.push({
                id: element.id_divisao,
                label: element.nome_divisao,
              });
            });
            this.carregando = false;
            if (this.regionais.length == 2 && this.divisoes.length == 2)
              this.passo = 1;

            resolve();
          });
        }
      });
    },
    buscaPreCotacao() {
      this.idPreCotacao = this.$route.params.idPreCotacao;
      if (this.idPreCotacao) {
        this.carregando = true;
        service
          .post('PreCotacao', 'buscarPreCotacao', {
            idPreCotacao: this.idPreCotacao,
          })
          .then((res) => {
            this.carregando = false;
            res.data.resultado[0].idPropostaConvertida
              ? this.preCotacaoCovertida()
              : this.atribuiDadosPreCotacao(res.data.resultado[0]);
          })
          .catch((e) => {
            this.carregando = false;
          });
      }
    },
    atribuiDadosPreCotacao(data) {
      this.form.nome = data.nome;
      (this.form.celular = data.celular), (this.form.email = data.email);
      this.form.estado = data.estado;
      this.form.cidade = data.cidade;
      this.form.tipoProposta = 'Adesão';
      this.form.idGrupo.id = data.jsonRetorno.grupoSeleciondo.id;
      this.form.idGrupo.valor = data.jsonRetorno.grupoSeleciondo.label;
      if (
        typeof data.jsonRetorno.dadosPlaca === 'object' &&
        Object.hasOwn(data.jsonRetorno.dadosPlaca, 'placa')
      ) {
        this.form.placa = data.jsonRetorno.dadosPlaca.placa;
      }
    },
    preCotacaoCovertida() {
      this.$bvToast.toast('Essa Pre Cotação já foi covertida em uma cotação', {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
      this.$router.go(-1);
    },
    limpaCampos() {
      this.form.veiculo.modelo = null;
      this.form.veiculo.anoGeral = null;
      this.form.veiculo.anoFabricacao = null;
      this.form.veiculo.cilindradas = null;
      this.preencheModelo();
    },
    destacaGrupo(grupo) {
      if (!grupo || grupo) {
        return;
      }

      if (!this.verificaEmpresa() && grupo.hasOwnProperty('value')) {
        if (!grupo.value.id) {
          this.mostrarModalGrupos = true;
          this.selecaoDeGruposAtiva = true;
          this.alterarGrupoVeiculoCotacao = true;
          this.habilitaAlterarGrupo = false;
          return '';
        }
      }

      const _idGrupoSelecionado =
        grupo.value.id || this.grupoSelecionado.value.id;

      if (this.form.idGrupo.id == _idGrupoSelecionado) {
        return 'success';
      } else {
        return '';
      }
    },
    fecharModalGrupos() {
      this.mostrarModalGrupos = false;
      this.acaoBotao = 'Trocar';
    },
    buscarDicionarioTiposVeiculos() {
      service
        .post('dicionarios', 'buscar-dicionario-tipos-veiculos', {})
        .then((res) => {
          this.dicionarioTiposVeiculos = res.data;
        })
        .catch((e) => {});
    },
    buscarDicionarioChassisNacionais() {
      service
        .post('dicionarios', 'buscar-dicionario-chassis-nacionais', {})
        .then((res) => {
          this.dicionarioChassisNacionais = res.data;
        })
        .catch((e) => {});
    },
    verificaVeiculoImportado() {
      if (this.form.chassi) {
        const chassi = this.form.chassi.slice(0, 2);
        return !this.dicionarioChassisNacionais.includes(chassi.toUpperCase());
      } else {
        return false;
      }
    },
    separaGruposImportados(grupos, idTipoFipe) {
      const importado = this.verificaVeiculoImportado();
      const gruposImportados = grupos.filter(
        (grupo) =>
          grupo.importado === importado && grupo.idTipoFipe === idTipoFipe.id,
      );
      return gruposImportados.length ? gruposImportados : grupos;
    },
    reatribuiGrupoImportados() {
      if (this.form.chassi.length === 17 && this.form.idTipoFipe.id) {
        const gruposImportados = this.grupos.filter(
          (grupo) =>
            grupo.idTipoFipe === this.form.idTipoFipe.id && grupo.importado,
        );
        if (
          this.form.idTipoFipe !== null &&
          this.grupos.length > 0 &&
          gruposImportados.length > 0 &&
          this.verificaVeiculoImportado()
        ) {
          this.tiposVeiculos = gruposImportados.map((grupo) => {
            return {
              value: { id: grupo.idGrupo, valor: grupo.nomeGrupo },
              text: grupo.nomeGrupo,
              descricao: grupo.descricao,
            };
          });
          this.form.idGrupo = { id: null, valor: null };
          this.mostrarModalGrupos = true;
        }
      }
    },
    chassiToUpperCase(chassi) {
      if (chassi) {
        this.form.chassi = chassi.toUpperCase();
      }
    },
    verificaEmpresa() {
      return utils.verificaEmpresaBemProtege(this.form.idEmpresa);
    },
    ajustaAnoFabricaoZeroKm(ano) {
      const _anoFabricao =
        this.form.veiculo.anoFabricacao == '0Km' ? 32000 : ano;
      return _anoFabricao;
    },
    verificaCelular(telefone) {
      telefone = telefone.replace(/\D/g, '');
      const regexSequencial = /(\d)\1{8}/;
      if (!(telefone.length >= 10 && telefone.length <= 11)) {
        this.$bvToast.toast('Informe um número de celular válido.', {
          title: 'Atenção!',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
        return false;
      }

      //Se tiver 11 caracteres, verificar se começa com 9 o celular
      if (telefone.length == 11 && parseInt(telefone.substring(2, 3)) != 9) {
        this.$bvToast.toast('Informe um número de celular válido.', {
          title: 'Atenção!',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
        return false;
      }
      for (let n = 0; n < 10; n++) {
        if (
          telefone == new Array(11).join(n) ||
          telefone == new Array(12).join(n)
        ) {
          this.$bvToast.toast('Informe um número de celular válido.', {
            title: 'Atenção!',
            solid: true,
            variant: 'warning',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
          return false;
        }
      }
      const codigosDDD = [
        11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34,
        35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62,
        64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85,
        86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      ];
      if (codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) == -1) {
        this.$bvToast.toast('Informe um número de celular válido.', {
          title: 'Atenção!',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
        return false;
      }
      if (
        telefone.length == 10 &&
        [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3))) == -1
      ) {
        this.$bvToast.toast('Informe um número de celular válido.', {
          title: 'Atenção!',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
        return false;
      }
      if (regexSequencial.test(telefone)) {
        this.$bvToast.toast('Informe um número de celular válido.', {
          title: 'Atenção!',
          solid: true,
          variant: 'warning',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
        return false;
      }
      if (this.dadosConsultor || this.consultorCelular) {
        let celularConsultor = '';
        let celularConsultor2 = '';
        this.dadosConsultor
          ? (celularConsultor = this.dadosConsultor.celular.replace(/\D/g, ''))
          : '';
        this.consultorCelular
          ? (celularConsultor2 = this.consultorCelular.replace(/\D/g, ''))
          : '';
        if (telefone == (celularConsultor || celularConsultor2)) {
          this.$bvToast.toast(
            'Digite um número de celular diferente do consultor.',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
          return false;
        }
      }
      return true;
    },
    async buscaDadosConsultorApp() {
      const objSend = {
        idEmpresa: this.decoded.id_empresa,
        userName: this.decoded.userName,
      };
      try {
        const res = await service.post('Usuario', 'buscarUsuario', objSend);
        this.consultorCelular = res.data[0].celular;
      } catch (error) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Atenção',
          text: error,
          color: 'danger',
        });
      }
    },
    async buscaMarcasPorTipoVeiculo(tipoVeiculo) {
      let objSend = {
        tipoVeiculo: tipoVeiculo.id,
      };
      try {
        const res = await service.post(
          'Fipe',
          'consultar-marcas-por-tipo-veiculo',
          objSend,
        );
        this.marcas = res.data;
      } catch (error) {
        this.$bvToast.toast('Falha ao buscar marcas.', {
          title: 'Atenção!',
          solid: true,
          variant: 'danger',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        });
      }
    },

    async preencheGruposSemPlacaEncontrada() {
      if (!this.placaEncontrada) {
        const idMarca = this.form.veiculo.codigoMarca;
        const idModelo = this.form.veiculo.codigoModelo;
        const tipo = this.form.veiculo.tipoVeiculo || this.form.idTipoFipe.id;
        const anoFabricacao = this.form.veiculo.anoFabricacao;
        const anoModelo = this.form.veiculo.anoModelo;
        const _idEmpresa = this.form.idEmpresa;
        const objBuscaGrupo = {
          idEmpresa: _idEmpresa,
          idMarca,
          idModelo,
          tipo: tipo,
          anoFabricacao,
          anoModelo,
          importado: this.verificaEmpresa()
            ? this.verificaVeiculoImportado()
            : null,
        };
        this.tiposFipe = this.tiposFipeAux;
        // busca os grupos

        const grupoMarcaModelo = await service.post(
          'CotacaoAberta',
          'buscaGrupoMarcaModelo',
          objBuscaGrupo,
        );
        this.preencheGruposPosSelecao(grupoMarcaModelo.data, tipo);
        /*item.selecionado = true;*/
        this.buscaDadosFipe(
          'ConsultarModelosPorAno',
          'modelosPorAno',
          'name',
          'id',
        );
        this.tiposVeiculos.length > 1 ? (this.selecaoDeGruposAtiva = true) : '';
      }
    },
    async ajustarPropostaParaSomenteAgregado() {
      let _propostaParaSomenteAgregado = this.propostaParaSomenteAgregado;

      this.btnDisabled = _propostaParaSomenteAgregado ? false : true;

      this.btnVeiculoZeroHabilitado = _propostaParaSomenteAgregado
        ? true
        : false;

      if (_propostaParaSomenteAgregado) {
        this.isCarroZero = false;
      }

      if (
        (this.placaEncontrada && _propostaParaSomenteAgregado) ||
        !_propostaParaSomenteAgregado
      ) {
        this.placaEncontrada = false;
        this.modelosFipeEncontrados = [];
        this.grupos = [];
        this.idGrupo = null;
      }

      this.idGrupo = { id: null, label: '' };
      this.desabilitarInputVeiculoZero = _propostaParaSomenteAgregado
        ? true
        : false;

      this.idTipoFipeAux = _propostaParaSomenteAgregado
        ? { id: 3, valor: 'Caminhão' }
        : { id: null, valorCobertura: 'Selecione' };

      this.form.idTipoFipe = this.idTipoFipeAux;

      if (_propostaParaSomenteAgregado) {
        this.tiposVeiculos = [];
        await this.selecionaTipoFipe();
      }

      this.permitirSomenteAgregado = _propostaParaSomenteAgregado
        ? true
        : false;

      this.form.somenteAgregado = _propostaParaSomenteAgregado ? true : false;
      this.habilitaAlterarGrupo = _propostaParaSomenteAgregado ? false : true;
      this.selecaoDeGruposAtiva = _propostaParaSomenteAgregado ? true : false;

      if (_propostaParaSomenteAgregado) {
        this.form.veiculo.marca = null;
        this.form.veiculo.modelo = null;
      }

      if (this.tiposVeiculos.length > 1 && _propostaParaSomenteAgregado) {
        this.form.idGrupo = this.tiposVeiculos[0].value;
        this.mostrarModalGrupos = false;
        this.habilitaAlterarGrupo = true;
        this.selecaoDeGruposAtiva = true;
      }

      if (this.tiposVeiculos.length == 1 && _propostaParaSomenteAgregado) {
        this.form.idTipoFipe = this.idTipoFipeAux;
        this.mostrarModalGrupos = false;
        this.habilitaAlterarGrupo = true;
        this.grupoSelecionado = this.tiposVeiculos[0];
        this.form.idGrupo = this.tiposVeiculos[0].value;
        this.selecaoDeGruposAtiva = false;
      }
    },
    abrirModalNovoValorProduto(item) {
      this.novoValorProduto = item;
      this.mostrarModalNovoValorProduto = true;
    },
    buscarPermissoes() {
      service
        .getAll(
          'Login',
          null,
          `permissoes/${this.form.idEmpresa}/${this.idPerfil}`,
        )
        .then((res) => {
          this.userPermissoes = res.data;
        })
        .catch((e) => {});
    },
    async buscaFormasPagamentoPadroes() {
      try {
        this.carregando = true;

        const res = await service.post(
          'CotacaoAberta',
          'buscaFormasPagamentoSemProposta',
          {
            idEmpresa: this.form.idEmpresa,
            idProspect: this.form.idProspect,
            produtos: this.produtosObrigatorios,
          },
        );

        const { formasPagamento } = res.data;
        const _formaPagamentoPadraoEntrada = formasPagamento.find(
          (formPgto) =>
            formPgto.nomePagamento == 'Dinheiro' &&
            formPgto.nomeProduto == 'Entrada',
        );
        const _formaPagamentoPadraoMensal = formasPagamento.find(
          (formPgto) =>
            formPgto.nomePagamento == 'Boleto' &&
            formPgto.nomeProduto == 'Mensal',
        );
        this.formasPagamento = formasPagamento;
        this.formaPagamentoPadraoEntrada = _formaPagamentoPadraoEntrada;
        this.formaPagamentoPadraoMensal = _formaPagamentoPadraoMensal;

        this.carregando = false;
      } catch (error) {
        this.carregando = false;
        this.$bvToast.toast(
          'Falha ao buscar formas de pagamento, Tente novamente.',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3000,
          },
        );
      }
    },
    async buscaDadosPlaca() {
      try {
        if (this.form.placa) {
          this.carregando = true;
          const res = await service.post(
            'Cotacao',
            'BuscaDadosPlaca',
            this.form,
          );

          this.dadosPlaca = res.data;

          await this.preencheDadosPlaca();

          this.carregando = false;
        }
      } catch (error) {
        this.placaEncontrada = false;
        this.habilitaAlterarGrupo = false;
        this.carregando = false;

        this.$bvToast.toast(
          'Não foi possível encontrar a placa, preencha os dados manualmente',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'warning',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 5000,
          },
        );
      }

      if (this.permitirRestricoes == false) {
        this.passo = 4;
      }
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('topoForm')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
  },
  components: {
    topoInterno,
    Money,
    MenuLateral,
    FormComplemento,
    FormAgregado,
    FormDesconto,
    FormNovoValorProduto,
  },
  mounted() {
    this.produtosObrigatorios = this.produtos.filter(
      (x) => x.obrigatorio == true,
    );
    this.produtosNaoObrigatorios = this.produtos.filter(
      (x) => x.obrigatorio != true,
    );
    this.buscaDadosToken();
    this.buscaRestricoes();
    this.$validator.localize('en', locale);
    this.buscaRegionais('regionais', 'regionais', 'nome', 'idRegional');
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
    this.buscaDadosSelects(
      'domTipoFipe',
      'tiposFipe',
      'nomeTipo',
      'idTipoFipe',
    );
    this.buscarConfiguracaoEmpresa();
    this.buscaPreCotacao();
    this.buscarDicionarioChassisNacionais();
    this.buscarDicionarioTiposVeiculos();
    this.buscaDadosConsultorApp();
    this.buscarPermissoes();

    this.tiposFipeAux = this.tiposFipe;
  },
  created: function () {
    this.$watch('form', this.buscaProdutosVinculo, {
      deep: true,
    });
    /* this.$watch("form", this.buscaProdutosSobrepoe, {
      deep: true,
    });*/
  },
};
</script>
<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.custom-control-label {
  width: 100%;
}

.badge {
  font-size: 100%;
}
.NovaCotacao {
  padding-bottom: 80px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
.grupoNome {
  font-size: 1.2em;
  font-weight: 700;
}

.botaoDesconto {
  position: absolute;
  right: 5px;
  top: 2px;
  z-index: 9;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.iconeErro {
  color: red;
  font-size: 56px;
  margin: 20px 0;
}
.iconeSucesso {
  color: green;
  font-size: 56px;
  margin: 20px 0;
}
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}
.btn {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.topoForm {
  border-radius: 10px;
  border-left: 10px solid rgb(52, 138, 167);
  padding: 10px 20px;
  -webkit-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  margin: 10px;
  text-align: center;
  color: rgb(52, 138, 167);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.conteudoInterno .listaerros {
  font-size: 12px;
}

.restricoes .custom-checkbox:last-child {
  margin-top: 30px;
}

.valorprod {
  color: #000;
  font-weight: bolder;
  font-family: 'roboto';
}

.btnDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.ContainerPai {
  display: flex;
  flex-direction: row;
}

.ContainerRadio {
  display: flex;
  align-items: center;
}
</style>
